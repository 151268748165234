<template>
  <div
    v-show="(mainStore.isMobile && filterStore.isOpen) || !mainStore.isMobile"
    ref="filterRef"
    class="filter"
  >
    <div class="filter__wrapper">
      <div class="filter__content">
        <div v-if="mainStore.isMobile" class="filter__header">
          <span class="filter__header-title">Фильтр</span>
          <SvgIcon
            class="filter__header-close"
            icon="x"
            @click="filtersToggle"
          />
        </div>
        <div class="filter__body">
          <ul v-if="!mainStore.isMobile && filterStore.filters.selected" class="filter__list">
            <li
              v-for="(value, key) of filterStore.filters.selected"
              :key="key"
              class="filter__list-item"
            >
              <span class="filter__list-item-value">{{ value.title }}: {{ value.name }}</span>
              <button
                class="filter__list-item-remove"
                type="button"
                @click.stop="onRemoveFilterClick(key.toString())"
              >
                <SvgIcon icon="x" />
              </button>
            </li>
          </ul>
          <div
            class="filter__input"
          >
            <span v-if="mainStore.isMobile" class="filter__input-title">Поиск по фразе</span>
            <div class="filter__input-container">
              <input
                v-model="filterStore.filters.search"
                class="filter__input-field"
                :placeholder="mainStore.isMobile && filterStore.filters.selected ? mainStore.isMobile ? 'Поиск' : 'Введите фразу для поиска' : 'Введите поисковую фразу'"
                @keyup.enter="sendFilter"
                @mousedown="filterStore.isOpen = true"
              >
              <button
                class="filter__input-button"
              >
                <SvgIcon icon="search" />
              </button>
            </div>
          </div>
        </div>
        <component :is="filterComponent" v-show="mainStore.isMobile || (!mainStore.isMobile && filterStore.isOpen)" class="filter__component" @closePopup="closeFilter" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./FilterComponent.ts"></script>

<style scoped lang="scss" src="./FilterComponent.scss"></style>
