import type {
  ChangeAndroidAppDataPayload,
  ChangeIOSAppContactsPayload,
  ChangeIOSAppDataPayload,
  // MainAppInfoType,
  ChangeMainDataPayload,
  DeleteImagePayload,
  DeleteImageResponse,
  GetAppDataSuccessResponse,
  UploadAppImagesResponse,
  UploadIOSAndroidAppImages,
} from '@/types/app'
import axios from 'axios'

export const getAppDataApiCall = async (
  abortController?: AbortController,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/application', {
    signal: abortController?.signal,
  })
  return data
}

export const mainAppDataChangeApiCall = async (
  abortController?: AbortController,
  params?: ChangeMainDataPayload,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/application/main',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const iOSAppDataChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<ChangeIOSAppDataPayload>,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/application/ios',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const iOSAppContactsChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<ChangeIOSAppContactsPayload>,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/application/ios/contacts',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const androidAppDataChangeApiCall = async (
  abortController?: AbortController,
  params?: ChangeAndroidAppDataPayload,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/application/android',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const mainAppUploadImagesApiCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<UploadAppImagesResponse> => {
  const { data } = await axios.postForm(
    '/dashboard/v1/chief/application/main',
    params,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const iosAppUploadImagesApiCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<UploadIOSAndroidAppImages> => {
  const { data } = await axios.postForm(
    '/dashboard/v1/chief/application/ios',
    params,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const androidAppUploadImagesApiCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<UploadIOSAndroidAppImages> => {
  const { data } = await axios.postForm(
    '/dashboard/v1/chief/application/android',
    params,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteImageApiCall = async (
  abortController?: AbortController,
  params?: DeleteImagePayload,
): Promise<DeleteImageResponse> => {
  const { data } = await axios.delete(`/dashboard/v1/media`, {
    signal: abortController?.signal,
    params,
  })
  return data
}
