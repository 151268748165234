<template>
  <section class="home">
    <section v-if="currentRoute.path === '/'" class="home__view">
      <section class="home__wrapper">
        <h1>Отображение элементов</h1>
        <AppTable
          :headings="HOME_ELEMENTS_TABLE_HEADINGS"
          class="home__table home__table_type_elems"
        >
          <template #rows>
            <AppLoader v-if="isMainPageDataLoading" class="home__table-loader" />
            <template v-else>
              <div
                v-for="(val, key) of elemsFields"
                :key="key"
                class="home__table-row-elems"
              >
                <div class="home__table-container">
                  <span>Элемент</span>
                  {{ HOME_ELEMENTS_TITLES[key] }}
                </div>
                <div class="home__table-container">
                  <span>Активность</span>
                  <div class="home__table-row-elems--wrapper">
                    <ToggleElement
                      :value="!val"
                      :checked="val"
                      :fieldKey="key"
                      :class="{ disabled: isUpdateMainPageLoading }"
                      @update:value="onUpdateElemsValue"
                    />
                    <TooltipComponent
                      v-if="key === 'sliderS'"
                      text="Можно выбрать только один вид слайдера, либо ни одного"
                      class="home__table-row-elems--tip"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </AppTable>
      </section>
      <section class="home__wrapper">
        <h2>Рекламные баннеры</h2>
        <AppTable
          :headings="HOME_AD_BANNERS_TABLE_HEADINGS"
          class="home__table_type_ad"
        >
          <template #rows>
            <div v-if="banners.length">
              <div
                v-for="{ id, title, categoryId, image, isActive } in banners"
                :key="id"
                class="home__table-row-banners"
              >
                <div class="home__table-container">
                  <span>Наименование</span>
                  <p>{{ title }}</p>
                </div>
                <div class="home__table-container">
                  <span>Категория</span>
                  <p>{{ getBannerCategory(+categoryId) }}</p>
                </div>
                <div class="home__table-container">
                  <span>Фото</span>
                  <img
                    :src="image.previewUrl"
                    :img="`Изображение ${title}`"
                    class="home__table-row-banners--img"
                  >
                </div>
                <div class="home__table-container">
                  <span>Активность</span>
                  <ToggleElement
                    :value="!isActive"
                    :checked="isActive"
                    fieldKey="isActive"
                    @update:value="onUpdateBannersValue(id, !isActive)"
                  />
                </div>
                <div class="home__table-row-banners--btn-container">
                  <EditIconBtn @click="openBannerForm(id)" />
                  <DeleteIconBtn @click="removeBannerClick(id)" />
                </div>
              </div>
            </div>
            <span v-else class="home__message">Отсутствуют</span>
          </template>
        </AppTable>
        <AddBtn class="home__add-btn" @click="openBannerForm">
          Добавить баннер
        </AddBtn>
      </section>
      <HomeInfoBlocks />
      <Teleport to="body">
        <AppPopup
          :title="!!selectedBanner ? 'Редактировать баннер' : 'Создать баннер'"
          :isOpen="openedPopup.banner"
          @close="closeBannerForm"
        >
          <HomeBannerForm
            :banner="selectedBanner"
            :categories="categories"
            @close="closeBannerForm"
          />
        </AppPopup>
      </Teleport>
      <Teleport to="body">
        <AppPopup
          title="Подтвердите действие"
          :isOpen="openedPopup.confirm"
          @close="closeConfirmPopup"
        >
          <ConfirmPopup @agree="handleBannerRemove" @cancel="closeConfirmPopup">
            <!-- @vue-expect-error -->
            <template #content>
              <div class="home__confirm">
                <p>Вы действительно хотите удалить рекламный баннер?</p>
                <NotifyComponent
                  v-if="confirmBannerError"
                  :text="confirmBannerError"
                />
              </div>
            </template>
          </ConfirmPopup>
        </AppPopup>
      </Teleport>
    </section>
    <RouterView v-else />
  </section>
</template>

<script lang="ts" src="./HomeView.ts"></script>

<style scoped lang="scss" src="./HomeView.scss"></style>
