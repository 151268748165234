import type { TableItem } from '@/types/table'

export const MAIN_APP_HEADINGS = {
  companyName: 'Название компании',
  iconName: 'Название приложения для иконки в телефоне',
  splashScreenText: 'Фраза для Splash экрана',
}

export const IOS_MAIN_HEADINGS = {
  appIcon: 'Иконка для приложения',
  title: 'Название приложения для AppStore',
  userAgreementUrl: 'Ссылка на политику конфиденциальности',
  supportUrl: 'URL адрес службы поддержки вашего сервиса',
  description: 'Описание приложения, раскрывающее его функции и возможности',
  keyWords:
    'Добавьте одно или несколько ключевых слов (через запятую), описывающих приложение. Поиск в App Store по ключевым словам дает более точные результаты. Разделяйте ключевые слова стандартной или китайской запятой, либо используйте оба знака',
}

export const IOS_CONTACTS_HEADINGS = {
  name: 'ФИО',
  address: 'Адрес',
  email: 'E-mail',
  phone: 'Телефон',
  copyright:
    'Авторские права (Имя лица или название организации, которые владеют уникальными правами на приложение; указывается после года получения прав (например, “2008 ИП Мванов И.И.”) Не указывайте URL-адрес.)',
}

export const IOS_GALLERY_HEADINGS = {
  'iphone55-8': 'iPhone 5,5: iPhone 8 Plus: 1242 px - 2208 px',
  'iphone65-11': 'iPhone 6,5: iPhone 11 Pro Max: 1242 px - 2688 px',
  'iphone67-14': 'iPhone 6,7: iPhone 14 Pro Max: 1242 px - 2688 px',
  'ipadPro3': 'iPad Pro (3 поколения): 2048 px - 2732 px',
}

export const ANDROID_INFO_HEADINGS = {
  appIcon: 'Иконка для приложения',
  title: 'Название приложения (не более 30 символов)',
  shortDescription: 'Краткое описание приложения (до 80 символов)',
  description: 'Длинное описание приложения (до 4 000 символов)',
  userAgreementUrl: 'Ссылка на политику конфиденциальности',
  defaultLanguage: 'Язык приложения по умолчанию',
  appCategory: 'Категория приложения',
  userDeleteFormUrl: 'Ссылка на форму запроса удаления аккаунта пользователя',
  images: 'Изображения для Play Market',
}

export const ANDROID_GALLERY_HEADINGS = {
  marketBanner: 'Рекламный баннер для Play Market',
  marketImage: 'Изображения для Play Market (от 2 до 10 шт)',
}

export const ANDROID_GALLERY_SUBHEADINGS = {
  marketBanner: 'Размер 1024 px х 500 px, формат png/jpg',
  marketImage:
    'Соотношение 16х9, размер от 320 px до 3840 px по каждой стороне',
}

export const COLOR_TITLES = {
  primary: 'Основной цвет',
  secondary: 'Вторичный цвет',
  icon: 'Цвет иконки',
  background1: 'Цвет фона 1',
  background2: 'Цвет фона 2',
  text: 'Цвет текста',
  gradient: 'Градиент',
  gradient_1: 'Градиент 1',
  gradient_2: 'Градиент 2',
}

export const LOYALTY_FIELDS_HEADINGS = {
  delay: 'Отсрочка начисления (ч.)',
  rate: 'Бонусов в 1 рубле',
  annualAmount: 'Ежегодные бонусы',
  registerAmount: 'Бонусы за регистрацию',
  birthdayAmount: 'Бонусы на день рождения',
  dontAddWhenPaidWithBonus: 'Бонусы не начисляются при оплате бонусами',
  dontAddForBonuses: 'Начислять только на часть оплаченную деньгами',
}

export const PAYMENT_HEADINGS = {
  siteId: 'Site id',
  secret: 'Secret',
  publicKey: 'Public key',
  inn: 'ИНН',
  ffd: 'Версия',
  paymentAddress: 'Место расчетов',
  vat: 'Номер налога в ККТ',
  sno: 'Система налогообложения',
}

export const PROFILE_TABLE_HEADINGS = [
  {
    text: 'Системное название',
    value: 'systemName',
  },
  { text: 'Пользовательское  название', value: 'userName' },
  { text: 'Активность', value: 'active' },
]

export const HOME_ELEMENTS_TABLE_HEADINGS = [
  {
    text: 'Элемент',
  },
  {
    text: 'Активность',
  },
]

export const HOME_ELEMENTS_TITLES = {
  sliderL: 'Большие рекламные баннеры',
  sliderS: 'Маленькие рекламные баннеры',
  loyalty: 'Программа лояльности',
  myBookings: 'Мои записи',
  myOrders: 'Мои заказы',
}

export const HOME_AD_BANNERS_TABLE_HEADINGS = [
  {
    text: 'Наименование ',
  },
  {
    text: 'Категория',
  },
  {
    text: 'Фото',
  },
  {
    text: 'Активность',
  },
]

export const HOME_AD_BANNERS_TITLES = {
  largeBanners: 'Большие рекламные баннеры',
  smallBanners: 'Маленькие рекламные баннеры',
  loyalty: 'Программа лояльности',
  appointments: 'Мои записи',
  orders: 'Мои заказы',
}

export const NEWS_TABLE_HEADINGS: TableItem[] = [
  {
    title: 'Название',
    show: true,
  },
  {
    title: 'Фото',
    show: true,
  },
  {
    title: 'Описание',
    show: true,
  },
  {
    title: 'Дата создания',
    show: true,
  },
]

export const FINANCE_TABLE_HEADINGS = [
  {
    text: 'Номер заказа',
  },
  {
    text: 'Дата и время',
  },
  {
    text: 'Заказ',
  },
  {
    text: 'Сумма',
  },
  {
    text: 'Клиент',
  },
  {
    text: 'Комментарий',
  },
]

export const ORDERS_TABLE_HEADINGS = [{
  text: 'Номер',
}, {
  text: 'Фото',
}, {
  text: 'Наименование',
}, {
  text: 'Количество',
}, {
  text: 'Цена, ₽',
}, {
  text: 'Цена, (скид.)',
}, {
  text: 'Сумма, ₽',
}]
