<template>
  <form ref="formRef" class="registry" autocomplete="off" novalidate @submit.prevent="submitHandler">
    <h1 class="registry__title">
      Регистрация
    </h1>
    <div class="registry__fields">
      <fieldset
        v-for="(val, key) in fields"
        :key="key"
        class="registry__fields-set"
      >
        <FormElement
          v-if="key !== 'phone'"
          v-model:value.trim="fields[key]"
          :placeholder="FIELDS_PLACEHOLDERS[key]"
          required
          :name="key"
          :autocomplete="`off-${key}`"
          :type="fieldsTypes[key]"
          :errorText="
            errorFields && errorFields.data ? errorFields.data[key] : null
          "
          :disabled="isCodeStep || isPhoneChangeClicked"
          :isPlaceholderStatic="false"
        />
        <FormElement
          v-else
          v-model:value="fields[key]"
          v-maska="key === 'phone' ? '+7 9## ###-##-##' : ''"
          tagName="input"
          :placeholder="FIELDS_PLACEHOLDERS[key]"
          required
          autocomplete="disabled"
          :type="fieldsTypes[key]"
          :errorText="
            errorFields && errorFields.data ? errorFields.data[key] : null
          "
          :disabled="isCodeStep"
          :isPlaceholderStatic="false"
        >
          <template v-if="key === 'phone' && !fields.phone" #icon>
            <SvgIcon icon="call" class="registry__fields-set-icon" />
          </template>
        </FormElement>
        <button
          v-if="
            ((key === 'password' && fields.password)
              || (key === 'repeatPassword' && fields.repeatPassword))
              && !isCodeStep
              && !isPhoneChangeClicked
          "
          type="button"
          class="registry__fields-set-btn"
          @click="showPassword(key)"
        >
          <SvgIcon :icon="arePasswordsShown[key] ? 'eye-on' : 'eye-off'" />
        </button>
      </fieldset>
    </div>
    <CodeComponent
      v-if="isCodeStep"
      v-model:code="code"
      :timer="timer"
      :phone="fields.phone.replace(PHONE_EXCESSIVE_CHARS_REG_EXP, '')"
      :isPhoneHidden="false"
      isRegistry
      @changeClick="onChangePhoneClick"
      @sendAgainClick="handleSendSMS"
    />
    <div class="registry__btn-container">
      <NotifyComponent v-if="reqError" :text="reqError" status="error" />
      <button type="submit" :disabled="isSubmitBtnDisabled || isLoading">
        Зарегистрироваться
      </button>
      <RouterLink
        v-if="!isCodeStep && !isPhoneChangeClicked"
        to="/login"
        class="registry__btn-container-link"
      >
        Уже есть аккаунт?
      </RouterLink>
    </div>
  </form>
</template>

<script lang="ts" src="./RegistryForm.ts"></script>

<style scoped lang="scss" src="./RegistryForm.scss"></style>
