import type {
  CatalogCategory,
  CatalogCategoryPayload,
  CatalogDropoutResponse,
  CatalogItemPayload,
  CatalogListProduct,
  CatalogProduct,
  CatalogResponse,
  CatalogSearchPayload,
  EditCategoryPayload,
  EditImageCategoryPayload,
  DeleteStorePayload,
  CatalogOffersPayload,
  OfferItem,
  AddCatalogProductPayload,
  SimilarItem,
  UpdateProductSimilarPayload,
  EditProductPayload,
  UpdateProductProperties,
  AddCatalogOfferPayload,
  CatalogOfferPayload,
  CatalogListSelectProduct,
} from '@/types/catalog'

import axios from 'axios'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

const getStoreCategoriesCall = async (
  abortController?: AbortController,
  params?: CatalogCategoryPayload,
): Promise<CatalogResponse<CatalogCategory[]>> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/categories', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getStoreCategoryCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/categories/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const addCategoryCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<CatalogCategory> => {
  const { data } = await axios.postForm(
    '/dashboard/v1/chief/market/categories',
    params,
    { signal: abortController?.signal },
  )
  return data
}

const addCategoryImageCall = async (
  abortController?: AbortController,
  params?: EditImageCategoryPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/market/categories/${params?.id}`,
    params?.data,
    { signal: abortController?.signal },
  )
  return data
}

const deleteCategoryImageApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/categories/${params?.id}/image`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const deleteStoreItemApiCall = async (
  abortController?: AbortController,
  params?: DeleteStorePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/${params?.type}/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const deleteOfferApiCall = async (
  abortController?: AbortController,
  params?: CatalogOfferPayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/products/${params?.id}/offers/${params?.offer_id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const editCategoryCall = async (
  abortController?: AbortController,
  params?: EditCategoryPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/categories/${params?.id}`,
    { ...params?.data },
    { signal: abortController?.signal },
  )
  return data
}

const getStoreSearchCategoriesCall = async (
  abortController?: AbortController,
  params?: CatalogSearchPayload,
): Promise<CatalogDropoutResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/categories/dropout?search=${params?.search}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const getStoreProductsCall = async (
  abortController?: AbortController,
  params?: CatalogCategoryPayload,
): Promise<CatalogResponse<CatalogListProduct[]>> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/products', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getStoreProductsListCall = async (
  abortController?: AbortController,
): Promise<CatalogListSelectProduct[]> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/products/list', {
    signal: abortController?.signal,
  })
  return data.data
}

const getStoreProductCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const addProductCall = async (
  abortController?: AbortController,
  params?: AddCatalogProductPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/market/products',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data.data
}

const addOfferCall = async (
  abortController?: AbortController,
  params?: AddCatalogOfferPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.post(
    `/dashboard/v1/chief/market/products/${params?.id}/offers`,
    {
      ...params?.data,
    },
    { signal: abortController?.signal },
  )
  return data.data
}

const editProductCall = async (
  abortController?: AbortController,
  params?: EditProductPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/products/${params?.id}`,
    { ...params?.data },
    { signal: abortController?.signal },
  )
  return data.data
}

const editOfferCall = async (
  abortController?: AbortController,
  params?: AddCatalogOfferPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/products/${params?.id}/offers/${params?.offer_id}`,
    { ...params?.data },
    { signal: abortController?.signal },
  )
  return data.data
}

const getStoreProductOffersCall = async (
  abortController?: AbortController,
  params?: CatalogOffersPayload,
): Promise<CatalogResponse<OfferItem[]>> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}/offers`, {
    signal: abortController?.signal,
    params: params?.data,
  })
  return data.data
}

const getStoreProductOfferCall = async (
  abortController?: AbortController,
  params?: CatalogOfferPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}/offers/${params?.offer_id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const getSimilarProductCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<SimilarItem[]> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}/similar`, {
    signal: abortController?.signal,
  })
  return data.data
}

const updateProductSimilarCall = async (
  abortController?: AbortController,
  params?: UpdateProductSimilarPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/products/${params?.id}/similar`,
    {
      ...params?.data,
    },
    { signal: abortController?.signal },
  )
  return data.data
}

const deleteProductSimilarApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/products/${params?.id}/similar/${params?.uuid}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const addProductImageCall = async (
  abortController?: AbortController,
  params?: EditImageCategoryPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/market/products/${params?.id}/image`,
    params?.data,
    { signal: abortController?.signal },
  )
  return data
}

const deleteProductImageApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/products/${params?.id}/image/${params?.uuid}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const updateProductPropertiesApiCall = async (
  abortController?: AbortController,
  params?: UpdateProductProperties,
): Promise<CatalogProduct> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/products/${params?.id}/properties`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data.data
}

export {
  addCategoryCall,
  addCategoryImageCall,
  addOfferCall,
  addProductCall,
  addProductImageCall,
  deleteCategoryImageApiCall,
  deleteOfferApiCall,
  deleteProductImageApiCall,
  deleteProductSimilarApiCall,
  deleteStoreItemApiCall,
  editCategoryCall,
  editOfferCall,
  editProductCall,
  getSimilarProductCall,
  getStoreCategoriesCall,
  getStoreCategoryCall,
  getStoreProductCall,
  getStoreProductOfferCall,
  getStoreProductOffersCall,
  getStoreProductsCall,
  getStoreProductsListCall,
  getStoreSearchCategoriesCall,
  updateProductPropertiesApiCall,
  updateProductSimilarCall,
}
