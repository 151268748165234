import { defineComponent, reactive, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/store/company'
import { useApiCall } from '@/composables/useApiCall'
import { createCompanyMenuItemApiCall } from '@/api/company'
import { COMPANY_MENU_ITEM_CANVAS_OPTIONS_TITLES } from '@/constants/company'
import type { ICompanyMenuTemplate, CreateMenuItemPayload, CreateUpdateMenuItemResponse } from '@/types/company'
import type { DefaultError } from '@/types/httpError'

interface ICompanyMenuFormFields {
  title: string
  type: ICompanyMenuTemplate['name']
}

interface ICompanyMenuTeplatesOption {
  value: ICompanyMenuTemplate['name']
  text: string
}

export default defineComponent({
  name: 'CompanyMenuForm',
  emits: ['close'],
  setup(_, { emit }) {
    const companyStore = useCompanyStore()
    const companyMenuItemCanvasOptions = ref<ICompanyMenuTeplatesOption[]>([])
    const error = ref('')
    const errorFields = ref<Record<string, string[]> | null>(null)
    const router = useRouter()

    const fields = reactive<ICompanyMenuFormFields>({
      title: '',
      type: 'html_text',
    })

    const onCanvasUpdate = (
      key: keyof typeof fields,
      value: { text: string, value: ICompanyMenuTemplate['name'] },
    ) => {
      fields[key] = value.value
    }

    const { isLoading, data: newCompanyMenuItem, executeApiCall: createCompanyMenuItem, error: newCompanyMenuitemError } = useApiCall<CreateUpdateMenuItemResponse, DefaultError, CreateMenuItemPayload>(createCompanyMenuItemApiCall, true)

    const resetErrors = () => {
      errorFields.value = null
      error.value = ''
    }

    const redirectToCompanyMenuItem = async (id: string | number) => {
      await router.push({ name: 'menuSection', params: { id } })
    }

    const handleSubmit = async () => {
      resetErrors()
      const body = {
        ...fields,
      }

      try {
        await createCompanyMenuItem(body)
        if (newCompanyMenuItem.value) {
          companyStore.menuSections = [...(companyStore.menuSections || []), newCompanyMenuItem.value.data]
        }
        emit('close')
        if (newCompanyMenuItem.value) {
          await redirectToCompanyMenuItem(newCompanyMenuItem.value.data.id)
        }
      } catch {
        if (newCompanyMenuitemError.value) {
          errorFields.value = newCompanyMenuitemError.value.data.data
          error.value = newCompanyMenuitemError.value.data.error
        }
      }
    }

    onBeforeMount(async () => {
      await companyStore.getMenuTemplates()
      if (companyStore.menuTemplates) {
        companyMenuItemCanvasOptions.value = companyStore.menuTemplates.map(template => ({ value: template.name, text: COMPANY_MENU_ITEM_CANVAS_OPTIONS_TITLES[template.name] }))
      }
    })
    return {
      companyMenuItemCanvasOptions,
      fields,
      error,
      errorFields,
      onCanvasUpdate,
      handleSubmit,
      isLoading,
    }
  },
})
