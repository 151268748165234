import { defineComponent, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import type { Interval } from '@/types/deliverySettings'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'SelectElement',
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [String, Object, null],
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    style: {
      type: Object,
      required: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
    objectKey: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
    },
    errorText: { type: Array, required: false },
    disabled: { type: Boolean, default: false },
    titleClass: {
      type: String as PropType<'medium-14' | 'medium-18'>,
      default: 'medium-14',
    },
  },

  setup(props, { emit }) {
    const selected = ref<unknown | null>(null)

    watch(
      () => selected.value,
      (newVal, oldValue) => {
        if (oldValue === newVal) {
          return
        }
        if (newVal === null) {
          emit('dataChanged', props.objectKey, undefined)
          return
        }
        if (props.multiple) {
          emit('dataChanged', props.objectKey, selected.value)
          return
        }
        emit('dataChanged', props.objectKey, selected.value)
      },
    )

    const intervalSelection = (values: Interval[]) => {
      return `${values.map((i) => i.interval).join(', ')}`
    }

    watch(
      () => props.value,
      () => {
        selected.value = props.value
      },
      {
        immediate: true,
      },
    )

    return { selected, intervalSelection }
  },
})
