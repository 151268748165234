<template>
  <form class="company-form" @submit.prevent="handleSubmit">
    <FormElement v-model:value="fields.title" title="Название" titleClass="black-18" placeholder="Введите название" :errorText="errorFields?.title" />
    <SelectElement
      :value="companyMenuItemCanvasOptions.length ? companyMenuItemCanvasOptions[0] : ''"
      :options="companyMenuItemCanvasOptions"
      title="Шаблон"
      objectKey="type"
      trackBy="value"
      label="text"
      titleClass="medium-18"
      :errorText="errorFields?.template"
      @dataChanged="onCanvasUpdate"
    />
    <NotifyComponent v-if="error" :text="error" />
    <FormActionBtns
      :isLoading="isLoading"
      :disabled="!fields.title.length"
      reversed
      @cancel="$emit('close')"
    >
      Создать раздел
    </FormActionBtns>
  </form>
</template>

<script lang="ts" src="./CompanyMenuForm.ts"></script>

<style scoped lang="scss" src="./CompanyMenuForm.scss"></style>
