import type { RootActions, RootGetters, RootState } from './types'
import { getSessionStorageItem } from '@/helpers/sessionStorage'
import { defineStore } from 'pinia'

const savedIsMenuOpen = getSessionStorageItem<boolean>('isMenuOpen')

export const useUIStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('ui', {
  state() {
    return {
      isMenuOpen: savedIsMenuOpen !== null ? savedIsMenuOpen : false,
      isFullscreenModalOpen: false,
    }
  },
  getters: {
    getIsMenuOpen(state: RootState) {
      return state.isMenuOpen
    },
  },
  actions: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
  },
})
