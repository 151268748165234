import type { PropType } from 'vue'
import { useMainStore } from '@/store/main/main'
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'

export default defineComponent({
  name: 'AddImage',
  props: {
    title: {
      type: String,
      required: false,
    },
    dropzoneTitle: {
      type: String,
      required: false,
      default: 'Добавить фото',
    },
    dropzoneDescription: {
      type: String,
      required: false,
      default: '1920 px - 1280 px; не более 3 Мб; jpg, png',
    },
    images: {
      type: Array as PropType<{ id: number | string, url: string, file?: File }[]>,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    columnCount: {
      type: Number,
      required: false,
      default: 1,
    },
    acceptTypes: {
      type: String,
      required: false,
      default: '',
    },
    keyItem: {
      type: String,
      required: false,
      default: '',
    },
    withOutDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['changeImage', 'deleteImage', 'addImage'],
  setup(props, { emit }) {
    const mainStore = useMainStore()
    const active = ref(false)
    const inputChangeRef = ref<HTMLElement | null>(null)
    const editId = ref<string | number | null>(null)
    const refImages = ref<HTMLImageElement | null>(null)
    const toggleActive = () => {
      active.value = !active.value
    }

    const editHandler = (id: number | string) => {
      if (inputChangeRef.value) {
        editId.value = id
        inputChangeRef.value.click()
      }
    }

    const onFileChange = (e: Event) => {
      if ((e.target as HTMLInputElement & EventTarget).files?.length) {
        emit('changeImage', (e.target as HTMLInputElement & EventTarget).files, editId.value, props.keyItem)
        editId.value = null
      }
    }

    const onFileAdd = (e: Event) => {
      if ((e.target as HTMLInputElement & EventTarget).files?.length) {
        emit('addImage', (e.target as HTMLInputElement & EventTarget).files, props.keyItem)
      }
    }

    const onDeleteImageClick = (id: string | number) => {
      emit('deleteImage', id, props.keyItem)
    }

    watch(() => refImages.value, () => {
      changeGridImages()
    })

    const changeGridImages = () => {
      if (refImages.value) {
        refImages.value.style.gridTemplateColumns = `repeat(${mainStore.isMobile ? 1 : props.columnCount}, 1fr)`
      }
    }

    onMounted(() => {
      window.addEventListener('resize', changeGridImages)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', changeGridImages)
    })

    return {
      inputChangeRef,
      onDeleteImageClick,
      toggleActive,
      onFileChange,
      onFileAdd,
      editHandler,
      refImages,
    }
  },
})
