import { useCatalogStore } from '@/store/catalog'
import { useFilterStore } from '@/store/filter'
import { defineComponent, onBeforeMount, ref, watch } from 'vue'

export default defineComponent({
  name: 'CatalogCategoriesFilter',
  emits: ['closePopup'],
  setup(_, { emit }) {
    const currentParent = ref<{ name: string, id: number } | null>(null)
    const currentActive = ref<null | 1 | 0>(null)
    const catalogStore = useCatalogStore()
    const filterStore = useFilterStore()

    const onMainFieldsValueUpdate = (value: { name: string, id: number }) => {
      filterStore.setFilterItem('parentId', {
        title: 'Родитель',
        name: value.name,
        value: value.id,
      })
    }

    const searchChange = async (value: string) => {
      await catalogStore.getParentCategories(value)
    }

    const changeActive = (value: 1 | 0) => {
      currentActive.value = value
      filterStore.setFilterItem('active', {
        title: 'Активность',
        name: value ? 'Да' : 'Нет',
        value,
      })
    }

    const applyFilter = async () => {
      await catalogStore.applyFilter({
        search: filterStore.filters.search,
        active: currentActive.value !== null ? filterStore.filters.selected?.active?.value.toString() : '',
        parentId: filterStore.filters.selected?.parentId?.value || '',
      }, 'categories')
      filterStore.isSend = false
      emit('closePopup')
    }

    const clearFilter = async () => {
      filterStore.clearFilter()
      await catalogStore.clearFilter('categories')
    }

    watch(() => filterStore.filters?.selected?.parentId, (value, oldValue) => {
      if (oldValue && !value) {
        currentParent.value = null
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.active, (value, oldValue) => {
      if (oldValue && !value) {
        currentActive.value = null
      }
    }, { deep: true })

    watch(() => filterStore.isSend, async () => {
      if (filterStore.isSend) {
        await applyFilter()
      }
    })

    onBeforeMount(async () => {
      await searchChange('')
    })

    return {
      catalogStore,
      searchChange,
      onMainFieldsValueUpdate,
      filterStore,
      currentParent,
      currentActive,
      changeActive,
      clearFilter,
      applyFilter,
    }
  },
})
