import type {
  CatalogCategory,
  CatalogCategoryPayload,
  CatalogDropoutResponse,
  CatalogListProduct,
  CatalogListSelectProduct,
  CatalogResponse,
  CatalogSearchPayload,
  DeleteStorePayload,
} from '@/types/catalog'
import type { DefaultError, DeleteError, HTTPError } from '@/types/httpError'
import {
  deleteStoreItemApiCall,
  getStoreCategoriesCall,
  getStoreProductsCall,
  getStoreProductsListCall,
  getStoreSearchCategoriesCall,
} from '@/api/catalog'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import type { DeleteSuccessResponse } from '@/types/main'

export const useCatalogStore = defineStore('catalog', () => {
  const categories = ref<CatalogCategory[]>([])
  const products = ref<CatalogListProduct[]>([])
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as CatalogCategoryPayload

  const {
    data: categoriesData,
    executeApiCall: categoriesAction,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useApiCall<
    CatalogResponse<CatalogCategory[]>,
    DefaultError,
    CatalogCategoryPayload
  >(getStoreCategoriesCall, true)

  const {
    data: productsData,
    executeApiCall: productsAction,
    isLoading: productsLoading,
    error: productsError,
  } = useApiCall<
    CatalogResponse<CatalogListProduct[]>,
    DefaultError,
    CatalogCategoryPayload
  >(getStoreProductsCall, true, filterFields)

  const { executeApiCall: deleteAction, error: deleteError } = useApiCall<
    DeleteSuccessResponse,
    DeleteError,
    DeleteStorePayload
  >(deleteStoreItemApiCall, true)

  const isCategoriesLoading = computed(() => categoriesLoading.value)
  const isProductsLoading = computed(() => productsLoading.value)

  const errorValue = ref<HTTPError<DeleteError> | null>(null)

  const deleteCatalogItem = async (data: DeleteStorePayload) => {
    try {
      await deleteAction(data)
    } catch {
      if (deleteError.value) {
        errorValue.value = deleteError.value
      }
    }
  }

  const changePage = async (page: number, type: string) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    switch (type) {
      case 'products':
        await getProducts()
        return
      case 'categories':
        await getCategories()
    }
  }

  const clearFilter = async (type: string) => {
    clearQuery()
    await applyFilter(filterFields, type)
  }

  const applyFilter = async (filters: CatalogCategoryPayload, type: string) => {
    Object.keys(filters).forEach((key) => {
      if (
        filters[key as keyof CatalogCategoryPayload]
        || filters[key as keyof CatalogCategoryPayload] === '0'
      ) {
        filterFields[key as keyof CatalogCategoryPayload]
          = filters[key as keyof CatalogCategoryPayload]
      } else {
        filterFields[key as keyof CatalogCategoryPayload] = ''
      }
    })
    filterFields.page = 1
    if (type === 'categories') {
      await getCategories()
    } else {
      await getProducts()
    }
  }
  const applySort = async (value: string | null, type: string) => {
    if (value) {
      filterFields.sort = value
    } else {
      delete filterFields.sort
    }
    filterFields.page = 1
    if (type === 'categories') {
      await getCategories()
    } else {
      await getProducts()
    }
  }

  const getCategories = async () => {
    try {
      const filter = {} as CatalogCategoryPayload
      Object.keys(filterFields).forEach((key) => {
        if (
          filterFields[key as keyof CatalogCategoryPayload]
          || filterFields[key as keyof CatalogCategoryPayload] === false
        ) {
          filter[key as keyof CatalogCategoryPayload]
            = filterFields[key as keyof CatalogCategoryPayload]
        }
      })
      await categoriesAction(filter)
      if (categoriesData.value) {
        categories.value = [...categoriesData.value.data]
        metaData.total = categoriesData.value.pagination.total
        metaData.totalPages = categoriesData.value.pagination.totalPages
      }
    } catch {
      console.error(categoriesError.value)
    }
  }

  const getProducts = async () => {
    try {
      const filter = {} as CatalogCategoryPayload
      Object.keys(filterFields).forEach((key) => {
        if (
          filterFields[key as keyof CatalogCategoryPayload]
          || filterFields[key as keyof CatalogCategoryPayload] === false
        ) {
          filter[key as keyof CatalogCategoryPayload]
            = filterFields[key as keyof CatalogCategoryPayload]
        }
      })
      await productsAction(filter)
      if (productsData.value) {
        products.value = [...productsData.value.data]
        metaData.total = productsData.value.pagination.total
        metaData.totalPages = productsData.value.pagination.totalPages
      }
    } catch {
      console.error(productsError.value)
    }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    categories.value = []
    products.value = []
    errorValue.value = null
    Object.keys(filterFields).forEach(
      (n) => ['page', 'perPage'].includes(n) || delete filterFields[n],
    )
  }

  const {
    data: categoriesSelectData,
    executeApiCall: categoriesSelectAction,
    isLoading: categoriesSelectLoading,
  } = useApiCall<CatalogDropoutResponse, DefaultError, CatalogSearchPayload>(
    getStoreSearchCategoriesCall,
    true,
  )
  const parentCategories = computed(() => categoriesSelectData.value)
  const parentCategoriesLoading = computed(() => categoriesSelectLoading.value)

  const getParentCategories = async (value?: string) => {
    await categoriesSelectAction({ search: value || '' })
  }

  const {
    data: productsListData,
    executeApiCall: productsListAction,
    // isLoading: productsListLoading,
  } = useApiCall<
    CatalogListSelectProduct[],
    DefaultError,
    CatalogCategoryPayload
  >(getStoreProductsListCall, true, filterFields)

  const productsListForSelect = computed(() => productsListData.value)

  const getProductListForSelect = async (value?: string) => {
    await productsListAction({ search: value || '' })
  }

  return {
    categories,
    products,
    isCategoriesLoading,
    isProductsLoading,
    getCategories,
    getProducts,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    parentCategories,
    getParentCategories,
    productsListForSelect,
    getProductListForSelect,
    parentCategoriesLoading,
    applySort,
    deleteCatalogItem,
    errorValue,
  }
})
