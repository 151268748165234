<template>
  <div class="catalog-filter">
    <div class="catalog-filter__wrapper">
      <div class="catalog-filter__options">
        <span class="catalog-filter__options-title">Активность</span>
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Да"
          :value="1"
          :checked="currentActive === 1"
          @update:value="changeActive(1)"
        />
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Нет"
          :value="0"
          :checked="currentActive === 0"
          @update:value="changeActive(0)"
        />
      </div>
    </div>
    <div class="catalog-filter__actions">
      <button class="catalog-filter__actions-apply" @click="applyFilter">
        Применить
      </button>
      <button class="catalog-filter__actions-clear" @click="clearFilter">
        Очистить фильтр
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./ShopsFilter.ts">
</script>

<style scoped lang="scss" src="./ShopsFilter.scss"></style>
