import dayjs from 'dayjs'

export const formatDate = (
  date: string | number,
  formatType?: 'timeDate' | 'dateTime' | 'dateTimeStandard',
) => {
  const parsedDate = typeof date === 'number' ? dayjs.unix(date) : dayjs(date)

  switch (formatType) {
    case 'timeDate':
      return parsedDate.format('HH:mm DD.MM.YYYY')
    case 'dateTime':
      return parsedDate.format('DD.MM.YYYY / HH:mm')
    case 'dateTimeStandard':
      return parsedDate.format('DD.MM.YYYY HH:mm')
    default:
      return parsedDate.format('DD.MM.YYYY')
  }
}

export const checkDateValidityByDays = (date: string) => {
  const dateString = dayjs(date).format('YYYY-MM-DD')
  const now = dayjs()
  return now.diff(dateString, 'day')
}
