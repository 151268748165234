import { defineComponent, ref, computed, reactive } from 'vue'
import CompanyMenuForm from '@/components/CompanyMenuForm/CompanyMenuForm.vue'
import { useCompanyStore } from '@/store/company'
import { useRoute, useRouter } from 'vue-router'
import type { DefaultError } from '@/types/httpError'

export default defineComponent({
  name: 'MenuComponent',
  components: {
    CompanyMenuForm,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const companyStore = useCompanyStore()

    const companySectionsMenu = computed(() => companyStore.menuSections ?? [])
    const isConfirmPopupOpen = ref(false)
    const isCompanyMenuItemFormOpen = ref(false)
    const selectedCompanyMenuItemId = ref<number | string>('')

    const isReqLoading = ref(false)
    const reqError = ref('')

    const menuItems = reactive({
      top: [
        {
          icon: 'home',
          title: 'Главная',
          path: '/',
        },
        {
          icon: 'catalog',
          title: 'Каталог',
          path: '/catalog',
          children: [
            {
              title: 'Категории',
              path: '/categories',
            },
            // {
            //   title: 'Комплекты',
            //   path: '/kits',
            // },
            {
              title: 'Товары',
              path: '/products',
            },
          ],
        },
        {
          icon: 'shops',
          title: 'Магазины',
          path: '/shops',
        },
        {
          icon: 'orders',
          title: 'Заказы',
          path: '/orders',
        },
        {
          icon: 'clients',
          title: 'Клиенты',
          path: '/clients',
        },
        {
          icon: 'info',
          title: 'О компании',
          path: '/company',
          children: [],
        },
        {
          icon: 'news',
          title: 'Новости',
          path: '/news',
        },
      ],
      bottom: [
        {
          icon: 'puzzle',
          title: 'Конструктор',
          path: '/builder',
        },
        {
          icon: 'money2',
          title: 'Финансы',
          path: '/finance',
        },
        {
          icon: 'setting',
          title: 'Настройки',
          path: '/settings',
        },
      ],
    })

    const isSelect = (parent: string) => {
      return route.fullPath.includes(parent)
    }

    const openedItem = ref<string[]>([])
    const isOpen = (icon: string) => {
      return openedItem.value.includes(icon)
    }
    const openChildren = (icon: string) => {
      if (isOpen(icon)) {
        openedItem.value = openedItem.value.filter((item) => item !== icon)
      } else {
        openedItem.value.push(icon)
      }
    }

    const onDeleteCompanyMenuItem = (id: number) => {
      selectedCompanyMenuItemId.value = id
      isConfirmPopupOpen.value = true
    }

    const handleCompanyMenuItemDelete = async () => {
      isReqLoading.value = true
      const id = selectedCompanyMenuItemId.value
      try {
        await companyStore.deleteMenuSection({ id: +id })
        onConfirmPopupClose()
        if (isSelect(`/company/${id}`)) {
          await router.push('/company')
        }
      } catch (error) {
        const typedError = error as DefaultError
        reqError.value = typedError.error
      } finally {
        isReqLoading.value = false
      }
    }

    const onConfirmPopupClose = () => {
      isConfirmPopupOpen.value = false
      selectedCompanyMenuItemId.value = ''
    }

    const toggleIsCompanyMenuItemFormOpen = () => {
      isCompanyMenuItemFormOpen.value = !isCompanyMenuItemFormOpen.value
    }

    return {
      menuItems,
      companySectionsMenu,
      isConfirmPopupOpen,
      isCompanyMenuItemFormOpen,
      isReqLoading,
      reqError,
      openChildren,
      isOpen,
      isSelect,
      onDeleteCompanyMenuItem,
      handleCompanyMenuItemDelete,
      onConfirmPopupClose,
      toggleIsCompanyMenuItemFormOpen,
    }
  },
})
