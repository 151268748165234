import type {
  Shop,
  ShopsPayload,
  ShopsResponse,
  ShopCreatePayload,
  ShopEditPayload,
} from '@/types/shops'
import type { ItemPayload, DefaultDeletePayload, DeleteSuccessResponse } from '../types/main'
import axios from 'axios'

const getShopsCall = async (
  abortController?: AbortController,
  params?: ShopsPayload,
): Promise<ShopsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/stores', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getShopsItemCall = async (
  abortController?: AbortController,
  params?: ItemPayload,
): Promise<Shop> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/stores/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const createShopCall = async (
  abortController?: AbortController,
  params?: ShopCreatePayload,
): Promise<Shop> => {
  const { data } = await axios.post('/dashboard/v1/chief/market/stores', { ...params }, {
    signal: abortController?.signal,
  })
  return data.data
}

const editShopCall = async (
  abortController?: AbortController,
  params?: ShopEditPayload,
): Promise<Shop> => {
  const { data } = await axios.put(`/dashboard/v1/chief/market/stores/${params?.id}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data.data
}

const deleteShopCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(`/dashboard/v1/chief/market/stores/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export { createShopCall, deleteShopCall, editShopCall, getShopsCall, getShopsItemCall }
