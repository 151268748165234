import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getProfileMenuItemsIconsListApiCall,
  getProfileMenuItemsListApiCall,
  createProfileMenuItemApiCall,
  editProfileMenuItemApiCall,
  deleteProfileMenuItemApiCall,
  getCompanyContactsApiCall,
  updateCompanyContactsApiCall,
  getNetworksApiCall,
  uploadCompanyMainPhotoApiCall,
  getCompanyMenuItemsListApiCall,
  getCompanyMenuTemplatesApiCall,
  getCompanyMenuItemApiCall,
  uploadCompanyMenuItemPhotoApiCall,
  deleteCompanyMenuItemApiCall,
} from '@/api/company'
import type { RootState, RootGetters, RootActions } from './types'
import type { DefaultError } from '@/types/httpError'
import type {
  ICompanyProfileMenuSection,
  ICompanyMenuItem,
  GetCompanyProfileMenuSectionsIconsResponse,
  GetCompanyProfileMenuSectionsResponse,
  CreateCompanyProfileMenuSectionPayload,
  CreateEditCompanyProfileMenuSectionResponse,
  EditCompanyProfileMenuSectionPayload,
  GetCompanyMenuItemsListResponse,
  GetCompanyMenuTemplatesResponse,
  GetCompanyMenuItemPayload,
  GetCompanyMenuItemResponse,
  CreateUpdateMenuItemResponse,
  UploadCompanyMenuItemPhotoPayload,
  CompanyMainPhotoResponseType,
  UpdateCompanyContactsPayload,
  CompanyContactsResponse,
  NetworksResponse,
} from '@/types/company'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const useCompanyStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('company', {
  state() {
    return {
      profileSectionIcons: null,
      profileSections: null,
      companyContacts: null,
      networks: null,
      menuSections: null,
      menuTemplates: null,
      selectedMenuSection: null,
      licenseMetaData: {
        total: 0,
        totalPages: 0,
      },
      licenseFilterFields: {
        page: 1,
        perPage: 15,
      },
      error: null,
    }
  },
  getters: {
    getSection(state: RootState) {
      return (id: number) => state.profileSections?.find((i) => i.id === id)
    },
  },
  actions: {
    clearLicenseQuery() {
      this.licenseMetaData = {
        total: 0,
        totalPages: 0,
      }
      this.licenseFilterFields = {
        page: 1,
        perPage: 15,
      }
    },
    setNewProfileSection(body: ICompanyProfileMenuSection) {
      if (this.profileSections) {
        this.profileSections.push(body)
      }
    },
    replaceProfileSection(id: number, body?: ICompanyProfileMenuSection) {
      if (this.profileSections) {
        if (body) {
          const index = this.profileSections.findIndex((i) => i.id === id)
          this.profileSections[index] = body
        } else {
          this.profileSections = this.profileSections.filter((i) => i.id !== id)
        }
      }
    },
    replaceMenuSection(id: number, body?: ICompanyMenuItem) {
      if (this.menuSections) {
        if (body) {
          const index = this.menuSections.findIndex((i) => i.id === id)
          this.menuSections[index] = body
        } else {
          this.menuSections = this.menuSections.filter((i) => i.id !== id)
        }
      }
    },
    async getProfileSectionIcons() {
      const {
        data: menuItemsIconsData,
        executeApiCall: getMenuItemsIconsList,
        error: menuItemsIconsError,
      } = useApiCall<GetCompanyProfileMenuSectionsIconsResponse, DefaultError>(
        getProfileMenuItemsIconsListApiCall,
        true,
      )

      try {
        await getMenuItemsIconsList()
        if (menuItemsIconsData.value) {
          this.profileSectionIcons = menuItemsIconsData.value.data
        }
      } catch {
        if (menuItemsIconsError.value) {
          console.error(menuItemsIconsError.value.data.error)
        }
      }
    },
    async getProfileSections() {
      const {
        data: menuItemsData,
        executeApiCall: getMenuItemsList,
        error: menuItemsError,
      } = useApiCall<GetCompanyProfileMenuSectionsResponse, DefaultError>(
        getProfileMenuItemsListApiCall,
        true,
      )

      try {
        await getMenuItemsList()
        if (menuItemsData.value) {
          this.profileSections = menuItemsData.value.data
        }
      } catch {
        if (menuItemsError.value) {
          console.error(menuItemsError.value.data.error)
        }
      }
    },
    async addProfileSection(params: CreateCompanyProfileMenuSectionPayload) {
      const {
        data,
        executeApiCall,
        error: profileSectionError,
      } = useApiCall<
        CreateEditCompanyProfileMenuSectionResponse,
        DefaultError,
        CreateCompanyProfileMenuSectionPayload
      >(createProfileMenuItemApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.setNewProfileSection(data.value.data)
        }
      } catch {
        if (profileSectionError.value) {
          this.error = profileSectionError.value.data
        }
        throw profileSectionError.value?.data
      }
    },
    async editProfileSection(params?: EditCompanyProfileMenuSectionPayload) {
      const {
        data,
        executeApiCall,
        error: profileSectionError,
      } = useApiCall<
        CreateEditCompanyProfileMenuSectionResponse,
        DefaultError,
        EditCompanyProfileMenuSectionPayload
      >(editProfileMenuItemApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          const menuItem = data.value.data
          this.replaceProfileSection(menuItem.id, menuItem)
        }
      } catch {
        if (profileSectionError.value) {
          this.error = profileSectionError.value.data
        }
        throw profileSectionError.value?.data
      }
    },
    async deleteProfileSection(params: DefaultDeletePayload) {
      const {
        data,
        executeApiCall,
        error: deleteProfileSectionError,
      } = useApiCall<DeleteSuccessResponse, DefaultError, DefaultDeletePayload>(
        deleteProfileMenuItemApiCall,
        true,
        params,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceProfileSection(params.id)
        }
      } catch {
        if (deleteProfileSectionError.value) {
          this.error = deleteProfileSectionError.value.data
        }
        throw deleteProfileSectionError.value?.data
      }
    },
    async getContacts() {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<CompanyContactsResponse, DefaultError>(
        getCompanyContactsApiCall,
        true,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.companyContacts = data.value.data
        }
      } catch {
        if (error.value) {
          console.error(error.value.data.error)
        }
      }
    },
    async updateContacts(params: UpdateCompanyContactsPayload) {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<CompanyContactsResponse, DefaultError, UpdateCompanyContactsPayload>(
        updateCompanyContactsApiCall,
        true,
        params,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.companyContacts = data.value.data
        }
      } catch {
        if (error.value) {
          console.error(error.value.data.error)
          throw error.value.data
        }
      }
    },
    async getNetworksList() {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<NetworksResponse, DefaultError>(
        getNetworksApiCall,
        true,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.networks = data.value.data
        }
      } catch {
        if (error.value) {
          console.error(error.value.data.error)
        }
      }
    },
    async uploadCompanyPhoto(params: FormData) {
      const { data: uploadImageData, executeApiCall: uploadImageAction, error: uploadImageError } = useApiCall<CompanyMainPhotoResponseType, DefaultError, FormData>(uploadCompanyMainPhotoApiCall, true, params, false)

      try {
        await uploadImageAction()
        if (uploadImageData.value) {
          return uploadImageData.value.data
        } else {
          return null
        }
      } catch {
        throw uploadImageError.value?.data
      }
    },
    async getMenuTemplates() {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<GetCompanyMenuTemplatesResponse, DefaultError>(
        getCompanyMenuTemplatesApiCall,
        true,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.menuTemplates = data.value.data
        }
      } catch {
        throw error.value?.data
      }
    },
    async getMenuSections() {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<GetCompanyMenuItemsListResponse, DefaultError>(
        getCompanyMenuItemsListApiCall,
        true,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.menuSections = data.value.data
        }
      } catch {
        throw error.value?.data
      }
    },
    async getMenuSection(params: GetCompanyMenuItemPayload) {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<GetCompanyMenuItemResponse, DefaultError, GetCompanyMenuItemPayload>(
        getCompanyMenuItemApiCall,
        true,
        params,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.selectedMenuSection = data.value.data
          if (data.value.data.image) {
            const { total, totalPages, currentPage, perPage } = data.value.data.image?.pagination
            this.licenseMetaData = { total, totalPages }
            this.licenseFilterFields = { page: currentPage, perPage }
          }
        }
      } catch {
        throw error.value?.data
      }
    },
    async changeLicensePage(page: number) {
      if (this.licenseFilterFields.page === page || !this.selectedMenuSection) {
        return
      }
      this.licenseFilterFields.page = page
      await this.getMenuSection({ id: this.selectedMenuSection.id, query: { page } })
    },
    async uploadMenuSectionPhoto(params: UploadCompanyMenuItemPhotoPayload) {
      const {
        data,
        executeApiCall,
        error,
      } = useApiCall<CreateUpdateMenuItemResponse, DefaultError, UploadCompanyMenuItemPhotoPayload>(
        uploadCompanyMenuItemPhotoApiCall,
        true,
        params,
        false,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.selectedMenuSection = data.value.data
        }
      } catch {
        throw error.value?.data
      }
    },
    async deleteMenuSection(params: DefaultDeletePayload) {
      const {
        data,
        executeApiCall,
        error: deleteProfileSectionError,
      } = useApiCall<DeleteSuccessResponse, DefaultError, DefaultDeletePayload>(
        deleteCompanyMenuItemApiCall,
        true,
        params,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceMenuSection(params.id)
        }
      } catch {
        if (deleteProfileSectionError.value) {
          this.error = deleteProfileSectionError.value.data
        }
        throw deleteProfileSectionError.value?.data
      }
    },
  },
})
