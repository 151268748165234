import { THOUSANDS_REG_EXP } from '@/constants/regExp'

export const normalizeMoneyString = (value: string) =>
  value.replace(/[\u00A0 ]/g, '')

export const formatMoney = (value: number): string => {
  let [integerPart, decimalPart] = value.toString().split('.')
  integerPart = integerPart.replace(THOUSANDS_REG_EXP, ' ')

  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart
}
