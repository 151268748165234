import { useFilterStore } from '@/store/filter'
import { useOrdersStore } from '@/store/orders'
import { useClientsStore } from '@/store/clients'
import { useSettingsStore } from '@/store/settings/settings'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { formatDate } from '@/helpers/date'
import { normalizeMoneyString } from '@/helpers/money'
import { defineComponent, ref, watch, computed, watchEffect, onBeforeMount } from 'vue'

export default defineComponent({
  name: 'CatalogProductsFilter',
  components: {
    DatePicker,
  },
  emits: ['closePopup'],
  setup(_, { emit }) {
    const client = ref<{ name: string, id: number } | null>(null)
    const currentStatus = ref<{ name: string, id: number }[]>([])

    const minTotal = ref('')
    const maxTotal = ref('')
    const dateFrom = ref<string | null>('')
    const dateTo = ref<string | null>('')

    const ordersStore = useOrdersStore()
    const filterStore = useFilterStore()
    const clientsStore = useClientsStore()
    const settingsStore = useSettingsStore()

    const statusesOptions = computed(() => settingsStore.storeStatusesForSelect ?? [])
    const clientOptions = computed(() => clientsStore.clientsForSelect)
    const isLoading = ref(false)

    const onMainFieldsValueUpdate = (value: { name: string, id: number }) => {
      filterStore.setFilterItem('client', {
        title: 'Клиент',
        name: value.name,
        value: value.id,
      })
    }

    const isStatusPresent = (id: number) => currentStatus.value.some(item => item.id === id)

    const changeStatus = (value: { name: string, id: number }) => {
      if (isStatusPresent(value.id)) {
        currentStatus.value = currentStatus.value.filter(item => item.id !== value.id)
      } else {
        currentStatus.value.push(value)
      }
      if (currentStatus.value.length) {
        filterStore.setFilterItem('status', {
          title: 'Статус',
          name: currentStatus.value.map(i => i.name).join(', '),
          value: currentStatus.value.map(i => i.id).join(','),
        })
      } else {
        delete filterStore.filters.selected?.status
      }
    }

    const applyFilter = async () => {
      const selected = filterStore.filters.selected
      await ordersStore.applyFilter({
        search: filterStore.filters.search,
        status: selected?.status?.value || '',
        user: selected?.client?.value || '',
        minTotal: selected?.total?.value.min ? normalizeMoneyString(selected.total.value.min) : '',
        maxTotal: selected?.total?.value.max ? normalizeMoneyString(selected.total.value.max) : '',
        dateFrom: selected?.interval?.value.from || '',
        dateTo: selected?.interval?.value.to || '',
      })
      filterStore.isSend = false
      emit('closePopup')
    }

    const clearFilter = async () => {
      filterStore.clearFilter()
      await ordersStore.clearFilter()
    }

    watch(() => filterStore.filters?.selected?.client, (value, oldValue) => {
      if (oldValue && !value) {
        client.value = null
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.status, (value, oldValue) => {
      if (oldValue && !value) {
        currentStatus.value = []
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.total, (value, oldValue) => {
      if (oldValue && !value) {
        minTotal.value = ''
        maxTotal.value = ''
      }
    }, { immediate: true })

    watch(() => filterStore.filters?.selected?.interval, (value, oldValue) => {
      if (oldValue && !value) {
        dateFrom.value = null
        dateTo.value = null
      }
    }, { immediate: true })

    watchEffect(() => {
      const min = minTotal.value
      const max = maxTotal.value

      if (!min && !max) {
        delete filterStore.filters.selected?.total
        return
      }

      filterStore.setFilterItem('total', {
        title: 'Сумма',
        name: min && max ? `${min} - ${max} ₽` : min ? `от ${min} ₽` : `до ${max} ₽`,
        value: { min, max },
      })
    })

    watchEffect(() => {
      const from = dateFrom.value ? formatDate(dateFrom.value) : null
      const to = dateTo.value ? formatDate(dateTo.value) : null

      if (!from && !to) {
        delete filterStore.filters.selected?.interval
        return
      }

      filterStore.setFilterItem('interval', {
        title: 'Период',
        name: from && to ? `${from} - ${to}` : from ? `от ${from}` : `до ${to}`,
        value: { from, to },
      })
    })

    watch(() => filterStore.isSend, async () => {
      if (filterStore.isSend) {
        await applyFilter()
      }
    })

    onBeforeMount(async () => {
      isLoading.value = true
      await clientsStore.getClientsForSelect()
      await settingsStore.getStoreStatusesForSelect()
      isLoading.value = false
    })

    return {
      ordersStore,
      onMainFieldsValueUpdate,
      filterStore,
      currentStatus,
      changeStatus,
      clearFilter,
      applyFilter,
      client,
      statusesOptions,
      isLoading,
      isStatusPresent,
      clientOptions,
      minTotal,
      maxTotal,
      dateFrom,
      dateTo,
      // validateMin,
    }
  },
})
