<template>
  <Transition name="popup">
    <div v-if="isOpen" class="popup" @mousedown.self="$emit('close')">
      <div class="popup__content">
        <div class="popup__header">
          <h2>{{ title }}</h2>
          <button type="button" @click="$emit('close')">
            <SvgIcon icon="x" />
          </button>
        </div>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" src="./AppPopup.ts"></script>

<style scoped lang="scss" src="./AppPopup.scss"></style>
