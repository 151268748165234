<template>
  <article class="info-block">
    <div class="info-block__header">
      <h4>{{ block.title }} (Шаблон: {{ getTemplateText }})</h4>
      <TagElement :status="block.isActive ? 'success' : 'fail'">
        {{ block.isActive ? 'Активен' : 'Не активен' }}
      </TagElement>
      <EditIconBtn
        class="info-block__edit-btn"
        @click="$emit('editBlockClick', block.id)"
      />
    </div>
    <AppTable
      v-if="!isNews"
      :headings="INFO_BLOCK_HEADINGS"
      class="info-block__table"
    >
      <template #rows>
        <div
          v-if="block.children.length"
        >
          <HomeInfoPage
            v-for="page of block.children"
            :key="page.id"
            :page="page"
            :block="{ id: block.id, template: block.template }"
            class="info-block__table-row"
            @edit="onPageEdit"
            @toggleIsActive="onTogglePageIsActive"
            @delete="onDeletePageBtnClick"
          />
        </div>
        <span v-else class="info-block__table-message">Отсутствуют</span>
      </template>
    </AppTable>
    <NotifyComponent
      v-else
      text="Новости редактируются через одноименный раздел меню"
      status="info"
      size="lg"
      class="info-block__message"
    />
    <div class="info-block__container" :class="{ 'single-child': isNews }">
      <AddBtn
        v-if="!isNews"
        @click="$emit('openPageForm', block.template, block.id, block.title)"
      >
        Добавить {{ addBtnText }}
      </AddBtn>
      <DeleteTextBtn @click="$emit('blockRemove', block.id)">
        Удалить блок
      </DeleteTextBtn>
    </div>
  </article>
</template>

<script lang="ts" src="./HomeInfoBlock.ts"></script>

<style scoped lang="scss" src="./HomeInfoBlock.scss"></style>
