import { defineComponent, computed } from 'vue'
import EditIconBtn from '@/components/ui/EditIconBtn/EditIconBtn.vue'
import HomeInfoPage from '@/components/HomeInfoPage/HomeInfoPage.vue'
import TagElement from '@/components/ui/TagElement/TagElement.vue'
import { useMainPageStore } from '@/store/mainPage'
import { useScroll } from '@/composables/useScroll'
import { INFO_BLOCK_HEADINGS, INFO_BLOCK_ADD_BTN_TEXT_PART } from '@/constants/company'
import type { PropType } from 'vue'
import type {
  IBlockPage,
  IMainPageBlock,
  UpdateMainPageBlockProductPayload,
  UpdateMainPagePromoPayload,
} from '@/types/mainPage'

export default defineComponent({
  name: 'HomeInfoBlock',
  components: {
    EditIconBtn,
    HomeInfoPage,
    TagElement,
  },
  props: {
    block: {
      type: Object as PropType<IMainPageBlock>,
      required: true,
    },
  },
  emits: ['editBlockClick', 'openPageForm', 'blockRemove', 'pageRemove'],
  setup(props, { emit }) {
    const isNews = computed(() => props.block.template === 'articles')
    const mainPageStore = useMainPageStore()
    const scroll = useScroll()

    const templateRuNames = {
      articles: 'Новости',
      promo: 'Акции',
      products: 'Товарная выборка',
    }

    const getTemplateText = computed(
      () =>
        templateRuNames[props.block.template as keyof typeof templateRuNames]
        ?? '-',
    )

    const addBtnText = computed(
      () =>
        INFO_BLOCK_ADD_BTN_TEXT_PART[
          props.block.template as keyof typeof INFO_BLOCK_ADD_BTN_TEXT_PART
        ] ?? 'элемент',
    )

    const onPageEdit = (page: IBlockPage) => {
      const { template, title, id } = props.block

      let position
      if (template === 'promo') {
        position = scroll.scrollY.value
      }

      emit('openPageForm', template, id, title, page, position)
    }

    const onTogglePageIsActive = async (id: number, value: boolean) => {
      switch (props.block.template) {
        case 'products':{
          const updateProductPageBody: UpdateMainPageBlockProductPayload = {
            blockId: props.block.id,
            pageId: id,
            data: {
              isActive: value,
            },
          }
          await mainPageStore.updateBlockProduct(updateProductPageBody)
          break
        }

        case 'promo': {
          const updatePromoPageBody: UpdateMainPagePromoPayload = {
            blockId: props.block.id,
            promoId: id,
            data: {
              isActive: value,
            },
          }
          await mainPageStore.updateBlockPromo(updatePromoPageBody)
          break
        }
        default:
          break
      }
    }

    const onDeletePageBtnClick = (id: number) => {
      const { template, id: blockId } = props.block
      emit('pageRemove', template, blockId, id)
    }

    return {
      INFO_BLOCK_HEADINGS,
      isNews,
      getTemplateText,
      addBtnText,
      onPageEdit,
      onTogglePageIsActive,
      onDeletePageBtnClick,
    }
  },
})
