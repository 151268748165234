import axios from 'axios'
import type {
  GetCompanyProfileMenuSectionsIconsResponse,
  ICompanyProfileMenuSection,
  GetCompanyProfileMenuSectionsResponse,
  CreateCompanyProfileMenuSectionPayload,
  CreateEditCompanyProfileMenuSectionResponse,
  EditCompanyProfileMenuSectionPayload,
  GetCompanyMenuItemsListResponse,
  GetCompanyMenuItemResponse,
  GetCompanyMenuTemplatesResponse,
  GetCompanyMenuItemPayload,
  CreateMenuItemPayload,
  CreateUpdateMenuItemResponse,
  UpdateCompanyMenuItemPayload,
  UploadCompanyMenuItemPhotoPayload,
  CompanyMainPhotoResponseType,
  CompanyContactsResponse,
  UpdateCompanyContactsPayload,
  NetworksResponse,
} from '@/types/company'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

// export const getProfileApiCall = async (abortController?: AbortController) => {
//   const { data } = await axios.get('/dashboard/v1/chief/profile/me', {
//     signal: abortController?.signal,
//   })
//   return data
// }

export const getProfileMenuItemsIconsListApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyProfileMenuSectionsIconsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/menu-items/icons', {
    signal: abortController?.signal,
  })
  return data
}

export const getProfileMenuItemsListApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyProfileMenuSectionsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/menu-items', {
    signal: abortController?.signal,
  })
  return data
}

export const getProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: ICompanyProfileMenuSection['id'],
) => {
  const { data } = await axios.get(`/dashboard/v1/chief/menu-items/${params}`, {
    signal: abortController?.signal,
  })
  return data
}

export const createProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: CreateCompanyProfileMenuSectionPayload,
): Promise<CreateEditCompanyProfileMenuSectionResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/menu-items',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const editProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: EditCompanyProfileMenuSectionPayload,
): Promise<CreateEditCompanyProfileMenuSectionResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/menu-items/${params?.id}`,
    {
      ...params?.data,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/menu-items/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getCompanyContactsApiCall = async (
  abortController?: AbortController,
): Promise<CompanyContactsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/contact-info', {
    signal: abortController?.signal,
  })
  return data
}

export const updateCompanyContactsApiCall = async (
  abortController?: AbortController,
  params?: UpdateCompanyContactsPayload,
): Promise<CompanyContactsResponse> => {
  const { data } = await axios.put('/dashboard/v1/chief/contact-info', { ...params }, {
    signal: abortController?.signal,
  })
  return data
}

export const getNetworksApiCall = async (
  abortController?: AbortController,
): Promise<NetworksResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/contact-info/networks', {
    signal: abortController?.signal,
  })
  return data
}

export const getCompanyMenuTemplatesApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyMenuTemplatesResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/about/templates', {
    signal: abortController?.signal,
  })
  return data
}

export const getCompanyMenuItemsListApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyMenuItemsListResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/about', {
    signal: abortController?.signal,
  })
  return data
}

export const getCompanyMenuItemApiCall = async (
  abortController?: AbortController,
  params?: GetCompanyMenuItemPayload,
): Promise<GetCompanyMenuItemResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/about/${params?.id}`, {
    signal: abortController?.signal,
    params: params?.query,
  })
  return data
}

export const createCompanyMenuItemApiCall = async (
  abortController?: AbortController,
  params?: CreateMenuItemPayload,
): Promise<CreateUpdateMenuItemResponse> => {
  const { data } = await axios.post('/dashboard/v1/chief/about', { ...params }, {
    signal: abortController?.signal,
  })
  return data
}

export const updateTextCompanyMenuItemApiCall = async (
  abortController?: AbortController,
  params?: UpdateCompanyMenuItemPayload,
): Promise<CreateUpdateMenuItemResponse> => {
  const { data } = await axios.put(`/dashboard/v1/chief/about/${params?.id}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data
}

export const getCompanyMainPhotoApiCall = async (
  abortController?: AbortController,
): Promise<CompanyMainPhotoResponseType> => {
  const { data } = await axios.get('/dashboard/v1/chief/about/image', {
    signal: abortController?.signal,
  })
  return data
}

export const uploadCompanyMainPhotoApiCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<CompanyMainPhotoResponseType> => {
  const { data } = await axios.postForm('/dashboard/v1/chief/about/image', params, {
    signal: abortController?.signal,
  })
  return data
}

export const uploadCompanyMenuItemPhotoApiCall = async (
  abortController?: AbortController,
  params?: UploadCompanyMenuItemPhotoPayload,
): Promise<CreateUpdateMenuItemResponse> => {
  const { data } = await axios.postForm(`/dashboard/v1/chief/about/${params?.id}`, params?.data, {
    signal: abortController?.signal,
  })
  return data
}

export const deleteCompanyMenuItemApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/about/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}
