import { defineComponent, ref, reactive, computed, onBeforeMount } from 'vue'
import EditIconBtn from '@/components/ui/EditIconBtn/EditIconBtn.vue'
import HomeBannerForm from '@/components/HomeBannerForm/HomeBannerForm.vue'
import HomeInfoBlocks from '@/components/HomeInfoBlocks/HomeInfoBlocks.vue'
import { useRouter } from 'vue-router'
import { useBannersStore } from '@/store/banners'
import { useCatalogStore } from '@/store/catalog'
import { useApiCall } from '@/composables/useApiCall'
import { useScroll } from '@/composables/useScroll'
import {
  getMainPageDataApiCall,
  updateMainPageDataApiCal,
} from '@/api/mainPage'
import {
  HOME_ELEMENTS_TABLE_HEADINGS,
  HOME_ELEMENTS_TITLES,
  HOME_AD_BANNERS_TABLE_HEADINGS,
  HOME_AD_BANNERS_TITLES,
} from '@/constants/headings'
import type { IBanner } from '@/types/banners'
import type { SelectItem } from '@/types/catalog'
import type { DefaultError } from '@/types/httpError'
import type {
  IMainPageData,
  UpdateMainPageDataPayload,
  GetMainPageDataResponse,
} from '@/types/mainPage'

export default defineComponent({
  name: 'HomeView',
  components: {
    EditIconBtn,
    HomeBannerForm,
    HomeInfoBlocks,
  },
  setup() {
    const bannersStore = useBannersStore()
    const catalogStore = useCatalogStore()

    const banners = computed(() => bannersStore.banners ?? [])
    const categories = ref<SelectItem[]>([])
    const elemsFields = ref<Omit<IMainPageData, 'id'>>({
      sliderL: true,
      sliderS: false,
      loyalty: true,
      myBookings: true,
      myOrders: true,
    })
    const selectedBanner = ref<Partial<IBanner> | null>(null)
    const openedPopup = reactive({
      confirm: false,
      banner: false,
    })
    const confirmBannerError = ref('')

    const router = useRouter()
    const currentRoute = computed(() => router.currentRoute.value)

    const scroll = useScroll()

    const {
      isLoading: isMainPageDataLoading,
      data: mainPageData,
      executeApiCall: getMainPageData,
      error: getMainPageError,
    } = useApiCall<GetMainPageDataResponse, DefaultError>(
      getMainPageDataApiCall,
      true,
    )

    const {
      isLoading: isUpdateMainPageLoading,
      executeApiCall: updateMainPageData,
      error: updateMainPageError,
    } = useApiCall<
      GetMainPageDataResponse,
      DefaultError,
      UpdateMainPageDataPayload
    >(updateMainPageDataApiCal, true)

    const onUpdateElemsValue = async (
      key: keyof Omit<IMainPageData, 'id'>,
      val: boolean,
    ) => {
      if (key === 'sliderL') {
        elemsFields.value.sliderL = val
        elemsFields.value.sliderS = !val
      } else if (key === 'sliderS') {
        elemsFields.value.sliderS = val
        elemsFields.value.sliderL = !val
      } else {
        elemsFields.value[key] = val
      }

      try {
        await updateMainPageData(elemsFields.value)
      } catch {
        if (updateMainPageError.value) {
          console.error(updateMainPageError.value.data.error)
        }
      }
    }

    const getBannerCategory = (id: number) =>
      categories.value.find((c) => c.id === id)?.name ?? '-'

    const onUpdateBannersValue = async (id: number, val: boolean) => {
      await bannersStore.updateBanner({ id, data: { isActive: val } })
    }

    const openBannerForm = (id?: number) => {
      const bannerToEdit = banners.value.find((b) => b.id === id)
      if (bannerToEdit) {
        selectedBanner.value = bannerToEdit
      }
      openedPopup.banner = true
    }

    const closeBannerForm = () => {
      if (selectedBanner.value) {
        selectedBanner.value = null
      }
      openedPopup.banner = false
    }

    const handleBannerRemove = async () => {
      if (selectedBanner.value?.id) {
        await bannersStore.deleteBanner({ id: selectedBanner.value.id })
      }

      if (bannersStore.error) {
        confirmBannerError.value = bannersStore.error.error
        return
      }
      openedPopup.confirm = false
    }

    const removeBannerClick = (id: number) => {
      selectedBanner.value = {
        id,
      }
      openedPopup.confirm = true
    }

    const closeConfirmPopup = () => {
      openedPopup.confirm = false
      selectedBanner.value = null
    }

    onBeforeMount(async () => {
      if (currentRoute.value.name === 'home') {
        try {
          await getMainPageData()
          if (mainPageData.value) {
            const { id, ...rest } = mainPageData.value.data
            elemsFields.value = rest
          }
        } catch {
          if (getMainPageError.value) {
            console.error(getMainPageError.value.data.error)
          }
        }
        await bannersStore.getBanners()
        await catalogStore.getParentCategories()
        if (catalogStore.parentCategories) {
          categories.value
            = catalogStore.parentCategories as unknown as SelectItem[]
        }

        const promoPosition = localStorage.getItem('promoPosition')

        if (promoPosition) {
          scroll.scrollTo(+promoPosition, 0)
          localStorage.removeItem('promoPosition')
        }
      }
    })

    return {
      HOME_ELEMENTS_TABLE_HEADINGS,
      HOME_ELEMENTS_TITLES,
      HOME_AD_BANNERS_TABLE_HEADINGS,
      HOME_AD_BANNERS_TITLES,
      currentRoute,
      banners,
      categories,
      elemsFields,
      selectedBanner,
      openedPopup,
      isMainPageDataLoading,
      isUpdateMainPageLoading,
      onUpdateElemsValue,
      getBannerCategory,
      onUpdateBannersValue,
      openBannerForm,
      closeBannerForm,
      handleBannerRemove,
      confirmBannerError,
      removeBannerClick,
      closeConfirmPopup,
    }
  },
})
