import type {
  Order,
  OrdersPayload,
  OrdersResponse,
  OrdersItemStatusChangePayload,
  OrdersItemChangePayload,
  OrderItemElementPayload,
  AddOrderItemElementPayload,
  EditOrderItemElementPayload,
  IOrderCompound,
} from '@/types/orders'
import type { ItemPayload, DeleteSuccessResponse } from '../types/main'
import axios from 'axios'

const getOrdersCall = async (
  abortController?: AbortController,
  params?: OrdersPayload,
): Promise<OrdersResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/orders', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getOrdersItemCall = async (
  abortController?: AbortController,
  params?: ItemPayload,
): Promise<Order> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/orders/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const updateOrdersItemStatusCall = async (
  abortController?: AbortController,
  params?: OrdersItemStatusChangePayload,
): Promise<Order> => {
  const { data } = await axios.patch(`/dashboard/v1/chief/market/orders/${params?.id}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data.data
}

const updateOrdersItemCall = async (
  abortController?: AbortController,
  params?: OrdersItemChangePayload,
): Promise<Order> => {
  const { data } = await axios.put(`/dashboard/v1/chief/market/orders/${params?.id}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data.data
}

const addOrdersItemElementCall = async (
  abortController?: AbortController,
  params?: AddOrderItemElementPayload,
): Promise<IOrderCompound> => {
  const { data } = await axios.post(`/dashboard/v1/chief/market/orders/${params?.id}/item`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data.data
}

const editOrdersItemElementCall = async (
  abortController?: AbortController,
  params?: EditOrderItemElementPayload,
): Promise<IOrderCompound> => {
  const { data } = await axios.patch(`/dashboard/v1/chief/market/orders/${params?.orderId}/item/${params?.elemId}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data.data
}

const deleteOrdersItemElementCall = async (
  abortController?: AbortController,
  params?: OrderItemElementPayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(`/dashboard/v1/chief/market/orders/${params?.orderId}/item/${params?.elemId}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export { addOrdersItemElementCall, deleteOrdersItemElementCall, editOrdersItemElementCall, getOrdersCall, getOrdersItemCall, updateOrdersItemCall, updateOrdersItemStatusCall }
