import type { DefaultError } from '@/types/httpError'
import type { Order, OrdersPayload, OrdersResponse, OrdersItemStatusChangePayload, IPaymentSystem, OrderItemElementPayload, OrdersItemChangePayload, AddOrderItemElementPayload, EditOrderItemElementPayload, IOrderCompound } from '@/types/orders'
import type { ItemPayload, DeleteSuccessResponse, IOption } from '@/types/main'
import {
  getOrdersCall,
  getOrdersItemCall,
  updateOrdersItemStatusCall,
  updateOrdersItemCall,
  addOrdersItemElementCall,
  editOrdersItemElementCall,
  deleteOrdersItemElementCall,
} from '@/api/orders'
import { getPaymentSystemsApiCall } from '@/api/payment'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useOrdersStore = defineStore('orders', () => {
  const orders = ref<Order[]>([])
  const openedOrder = ref<Order | null>(null)
  const paymentSystems = ref<IOption[]>([])
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as OrdersPayload

  const {
    data: ordersData,
    executeApiCall: ordersAction,
    isLoading: ordersLoading,
    error: ordersError,
  } = useApiCall<OrdersResponse, DefaultError, OrdersPayload>(
    getOrdersCall,
    true,
  )

  const isOrdersLoading = computed(() => ordersLoading.value)

  const changePage = async (page: number) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    await getOrders()
  }

  const clearFilter = async () => {
    clearQuery()
    await applyFilter(filterFields)
  }

  const applyFilter = async (filters: OrdersPayload) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key as keyof OrdersPayload] || filters[key as keyof OrdersPayload] === false) {
        filterFields[key as keyof OrdersPayload] = filters[key as keyof OrdersPayload]
      }
      else {
        filterFields[key as keyof OrdersPayload] = ''
      }
    })
    filterFields.page = 1
    await getOrders()
  }

  const applySort = async (value: string | null) => {
    if (value) {
      filterFields.sort = value
    }
    else {
      delete filterFields.sort
    }
    filterFields.page = 1
    await getOrders()
  }

  const getOrders = async () => {
    try {
      const filter = {} as OrdersPayload
      Object.keys(filterFields).forEach((key) => {
        if (filterFields[key as keyof OrdersPayload] || filterFields[key as keyof OrdersPayload] === false) {
          filter[key as keyof OrdersPayload] = filterFields[key as keyof OrdersPayload]
        }
      })
      await ordersAction(filter)
      if (ordersData.value) {
        orders.value = [...ordersData.value.data]
        metaData.total = ordersData.value.pagination.total
        metaData.totalPages = ordersData.value.pagination.totalPages
      }
    }
    catch {
      throw ordersError.value?.data
    }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    Object.keys(filterFields).forEach(n => ['page', 'perPage'].includes(n) || delete filterFields[n])
  }

  const {
    data: orderItemData,
    executeApiCall: orderItemAction,
    error: orderItemError,
  } = useApiCall<Order, DefaultError, ItemPayload>(
    getOrdersItemCall,
    true,
  )

  const getOrderItem = async (id: string) => {
    try {
      await orderItemAction({ id })
      if (orderItemData.value) {
        openedOrder.value = orderItemData.value
      }
    } catch {
      if (orderItemError.value) {
        throw orderItemError.value.data
      }
    }
  }

  const {
    data: updatedOrderItemStatusData,
    executeApiCall: updatedOrderItemStatusAction,
    error: updatedOrderItemStatusError,
  } = useApiCall<Order, DefaultError, OrdersItemStatusChangePayload>(
    updateOrdersItemStatusCall,
    true,
  )

  const updateOrderItemStatus = async (payload: OrdersItemStatusChangePayload) => {
    try {
      await updatedOrderItemStatusAction(payload)
      if (updatedOrderItemStatusData.value) {
        openedOrder.value = updatedOrderItemStatusData.value
      }
    } catch {
      if (updatedOrderItemStatusError.value) {
        throw updatedOrderItemStatusError.value.data
      }
    }
  }

  const {
    executeApiCall: updatedOrderItemAction,
    error: updatedOrderItemError,
  } = useApiCall<Order, DefaultError, OrdersItemChangePayload>(
    updateOrdersItemCall,
    true,
  )

  const updateOrderItem = async (payload: OrdersItemChangePayload) => {
    try {
      await updatedOrderItemAction(payload)
    } catch {
      if (updatedOrderItemError.value) {
        throw updatedOrderItemError.value.data
      }
    }
  }

  const {
    data: paymentSystemsData,
    executeApiCall: paymentSystemsAction,
    error: paymentSystemsError,
  } = useApiCall<IPaymentSystem[], DefaultError>(
    getPaymentSystemsApiCall,
    true,
  )

  const getPaymentSystems = async () => {
    try {
      await paymentSystemsAction()
      if (paymentSystemsData.value) {
        paymentSystems.value = paymentSystemsData.value.map((el) => ({ id: el.id, name: el.name }))
      }
    } catch {
      if (paymentSystemsError.value) {
        throw paymentSystemsError.value.data
      }
    }
  }

  const addOrderItemElement = async (params: AddOrderItemElementPayload): Promise<string | number | null> => {
    const { data, executeApiCall, error } = useApiCall<IOrderCompound, DefaultError, AddOrderItemElementPayload>(addOrdersItemElementCall, true, params)

    try {
      await executeApiCall()
      if (data.value) {
        return data.value.id
      }
    } catch {
      if (error.value) {
        throw error.value.data
      }
    }
    return null
  }

  const editOrderItemElement = async (params: EditOrderItemElementPayload) => {
    const { executeApiCall, error } = useApiCall<IOrderCompound, DefaultError, EditOrderItemElementPayload>(editOrdersItemElementCall, true, params, false)

    try {
      await executeApiCall()
    } catch {
      if (error.value) {
        throw error.value.data
      }
    }
  }

  const deleteOrderItemElement = async (params: OrderItemElementPayload) => {
    const { executeApiCall, error } = useApiCall<DeleteSuccessResponse, DefaultError, OrderItemElementPayload>(deleteOrdersItemElementCall, true, params, false)

    try {
      await executeApiCall()
    } catch {
      if (error.value) {
        throw error.value.data
      }
    }
  }

  return {
    orders,
    openedOrder,
    paymentSystems,
    isOrdersLoading,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    getOrders,
    applySort,
    getOrderItem,
    updateOrderItemStatus,
    updateOrderItem,
    getPaymentSystems,
    addOrderItemElement,
    editOrderItemElement,
    deleteOrderItemElement,
  }
})
