<template>
  <div class="catalog-filter">
    <div class="catalog-filter__wrapper">
      <AppLoader v-if="isLoading" />
      <div v-else class="catalog-filter__row">
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Статус</span>
          <CheckboxRadioElement v-for="status of statusesOptions" :key="status.id" class="catalog-filter__options-item" :label="status.name" :value="status.id" :checked="isStatusPresent(status.id)" @update:value="changeStatus(status)" />
        </div>
        <div class="catalog-filter__row-item">
          <MultiselectElement
            v-model="client"
            :options="clientOptions"
            label="name"
            title="Клиент"
            :allowEmpty="false"
            objectKey="id"
            trackBy="id"
            placeholder="Выберете клиента"
            class="catalog-filter__select"
            :clear-on-select="false"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :option-height="104"
            @update:modelValue="onMainFieldsValueUpdate"
          />
        </div>
      </div>
      <div class="catalog-filter__row">
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Сумма, ₽</span>
          <div class="catalog-filter__row-wrapper">
            <FormElement
              v-model:value="minTotal"
              v-maska
              data-maska-number-locale="ru"
              title=""
              tagName="input"
              placeholder="от"
              class="catalog-filter__input"
            />
            <span>-</span>
            <FormElement
              v-model:value="maxTotal"
              v-maska
              data-maska-number-locale="ru"
              title=""
              tagName="input"
              placeholder="до"
              class="catalog-filter__input"
            />
          </div>
        </div>
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Период</span>
          <div class="catalog-filter__row-wrapper">
            <DatePicker
              v-model:value="dateFrom"
              model-type="yyyy-MM-dd"
              placeholder="от"
              :enable-time-picker="false"
              :max-date="dateTo ? new Date(dateTo) : undefined"
              format="dd.MM.yyyy"
              hide-input-icon
              class="catalog-filter__input"
            />
            <span>-</span>
            <DatePicker
              v-model:value="dateTo"
              model-type="yyyy-MM-dd"
              placeholder="до"
              :enable-time-picker="false"
              :min-date="dateFrom ? new Date(dateFrom) : undefined"
              format="dd.MM.yyyy"
              hide-input-icon
              class="catalog-filter__input"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-filter__actions">
      <button class="catalog-filter__actions-apply" @click="applyFilter">
        Применить
      </button>
      <button class="catalog-filter__actions-clear" @click="clearFilter">
        Очистить фильтр
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./OrdersFilter.ts">
</script>

<style scoped lang="scss" src="./OrdersFilter.scss"></style>
