<template>
  <section class="info-blocks">
    <div class="info-blocks__header">
      <h3>Информационные блоки</h3>
      <button
        type="button"
        class="info-blocks__header-add-btn"
        @click="onOpenInfoForm()"
      >
        Добавить блок
      </button>
    </div>
    <AppLoader v-if="isLoading" />
    <draggableComponent
      v-else
      :list="blocks"
      group="blocks"
      :handle="isTouchDevice ? '.info-blocks__list-item-drag' : ''"
      class="info-blocks__list"
      itemKey="id"
      @start="isDragging = true"
      @change="onBlockDragAndDropEnd"
      @end="isDragging = false"
    >
      <template #item="{ element }">
        <div class="info-blocks__list-item">
          <div id="grag-icon" class="info-blocks__list-item-drag">
            <SvgIcon icon="menu" />
          </div>
          <HomeInfoBlock
            :block="element"
            @editBlockClick="onOpenInfoForm"
            @openPageForm="onOpenPageForm"
            @blockRemove="onDeleteBlockBtnClick"
            @pageRemove="onDeletePageBtnClick"
          />
        </div>
      </template>
    </draggableComponent>
    <Teleport to="body">
      <AppPopup
        :title="!!selectedBlock ? 'Редактировать блок' : 'Создать блок'"
        :isOpen="openedPopup.block"
        @close="onCloseInfoForm"
      >
        <HomeInfoForm :block="selectedBlock" @close="onCloseInfoForm" />
      </AppPopup>
    </Teleport>
    <Teleport to="body">
      <AppPopup
        class="info-blocks__page-popup"
        :title="pagePopupTitle"
        :isOpen="openedPopup.page"
        @close="onClosePageForm"
      >
        <HomePageForm :props="pagePopupProps" @close="onClosePageForm" />
      </AppPopup>
    </Teleport>
    <Teleport to="body">
      <AppPopup
        :isOpen="openedPopup.confirm"
        title="Подтвердите действие"
        @close="onCloseConfirmPopup"
      >
        <ConfirmPopup
          :isLoading="isConfirmReqLoading"
          @agree="deleteInstanceHandler"
          @cancel="onCloseConfirmPopup"
        >
          <!-- @vue-ignore -->
          <template #content>
            <div class="info-blocks__confirm-content">
              <p>{{ confirmPopupContent }}</p>
              <NotifyComponent v-if="reqError" :text="reqError" />
            </div>
          </template>
        </ConfirmPopup>
      </AppPopup>
    </Teleport>
  </section>
</template>

<script lang="ts" src="./HomeInfoBlocks.ts"></script>

<style scoped lang="scss" src="./HomeInfoBlocks.scss"></style>
