import type { App } from 'vue'
// import AppImageLoad from '@/components/AppImageLoad/AppImageLoad.vue'
import AppTable from '@/components/AppTable/AppTable.vue'
import FileLoader from '@/components/FileLoader/FileLoader.vue'
import FormElement from '@/components/FormElement/FormElement.vue'
// import ImageGallery from '@/components/ImageGallery/ImageGallery.vue'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent.vue'
import PaginationComponent from '@/components/PaginationComponent/PaginationComponent.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import AddBtn from '@/components/ui/AddBtn/AddBtn.vue'
import AddImage from '@/components/ui/AddImage/AddImage.vue'
import AppLoader from '@/components/ui/AppLoader/AppLoader.vue'
import AppPopup from '@/components/ui/AppPopup/AppPopup.vue'
import CheckboxRadioElement from '@/components/ui/CheckboxRadioElement/CheckboxRadioElement.vue'
import ConfirmPopup from '@/components/ui/ConfirmPopup/ConfirmPopup.vue'
import DeleteIconBtn from '@/components/ui/DeleteIconBtn/DeleteIconBtn.vue'
import DeleteTextBtn from '@/components/ui/DeleteTextBtn/DeleteTextBtn.vue'
import EditBtn from '@/components/ui/EditBtn/EditBtn.vue'
import FormActionBtns from '@/components/ui/FormActionBtns/FormActionBtns.vue'
import MultiselectElement from '@/components/ui/MultiselectElement/MultiselectElement.vue'
import SelectElement from '@/components/ui/SelectElement/SelectElement.vue'
import TabsNav from '@/components/ui/TabsNav/TabsNav.vue'
import ToggleElement from '@/components/ui/ToggleElement/ToggleElement.vue'
import TooltipComponent from '@/components/ui/TooltipComponent/TooltipComponent.vue'
// import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ContextMenu from '@/components/ContextMenu/ContextMenu.vue'

const globalComponents = (app: App) => {
  app.component('SvgIcon', SvgIcon)
  app.component('NotifyComponent', NotifyComponent)
  app.component('FormElement', FormElement)
  app.component('FormActionBtns', FormActionBtns)
  app.component('FileLoader', FileLoader)
  app.component('EditBtn', EditBtn)
  app.component('AddBtn', AddBtn)
  app.component('AppLoader', AppLoader)
  // app.component('AppImageLoad', AppImageLoad)
  app.component('SelectElement', SelectElement)
  app.component('CheckboxRadioElement', CheckboxRadioElement)
  app.component('ToggleElement', ToggleElement)
  app.component('TabsNav', TabsNav)
  app.component('AppTable', AppTable)
  app.component('DeleteIconBtn', DeleteIconBtn)
  app.component('TooltipComponent', TooltipComponent)
  app.component('AppPopup', AppPopup)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('DeleteTextBtn', DeleteTextBtn)
  app.component('PaginationComponent', PaginationComponent)
  app.component('AddImage', AddImage)
  app.component('MultiselectElement', MultiselectElement)
  // app.component('DatePicker', DatePicker)
  app.component('ContextMenu', ContextMenu)
}

export default globalComponents
