import type { DefaultError } from '@/types/httpError'
import type { Shop, ShopsPayload, ShopsResponse } from '@/types/shops'
import type { ItemPayload, DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'
import {
  getShopsCall,
  getShopsItemCall,
  deleteShopCall,
} from '@/api/shops'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useShopsStore = defineStore('shops', () => {
  const shops = ref<Shop[]>([])
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as ShopsPayload
  const shopItem = ref<Shop | null>(null)

  const {
    data: shopsData,
    executeApiCall: shopsAction,
    isLoading: shopsLoading,
    error: shopsError,
  } = useApiCall<ShopsResponse, DefaultError, ShopsPayload>(
    getShopsCall,
    true,
  )

  const isShopsLoading = computed(() => shopsLoading.value)

  const changePage = async (page: number) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    await getShops()
  }

  const clearFilter = async () => {
    clearQuery()
    await applyFilter(filterFields)
  }

  const applyFilter = async (filters: ShopsPayload) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key as keyof ShopsPayload] || filters[key as keyof ShopsPayload] === '0') {
        filterFields[key as keyof ShopsPayload] = filters[key as keyof ShopsPayload]
      }
      else {
        filterFields[key as keyof ShopsPayload] = ''
      }
    })
    filterFields.page = 1
    await getShops()
  }

  const applySort = async (value: string | null) => {
    if (value) {
      filterFields.sort = value
    }
    else {
      delete filterFields.sort
    }
    filterFields.page = 1
    await getShops()
  }

  const getShops = async () => {
    try {
      const filter = {} as ShopsPayload
      Object.keys(filterFields).forEach((key) => {
        if (filterFields[key as keyof ShopsPayload] || filterFields[key as keyof ShopsPayload] === false) {
          filter[key as keyof ShopsPayload] = filterFields[key as keyof ShopsPayload]
        }
      })
      await shopsAction(filter)
      if (shopsData.value) {
        shops.value = [...shopsData.value.data]
        metaData.total = shopsData.value.pagination.total
        metaData.totalPages = shopsData.value.pagination.totalPages
      }
    }
    catch {
      console.error(shopsError.value)
    }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    Object.keys(filterFields).forEach(n => ['page', 'perPage'].includes(n) || delete filterFields[n])
  }

  const { data: shopItemData, executeApiCall: shopItemAction, error: shopItemError } = useApiCall<Shop, DefaultError, ItemPayload>(getShopsItemCall, true)

  const getShopItem = async (id: number) => {
    try {
      await shopItemAction({ id })
      if (shopItemData.value) {
        shopItem.value = shopItemData.value
      }
    } catch {
      throw shopItemError.value?.data
    }
  }

  const { executeApiCall: deleteShopAction, error: deleteShopError } = useApiCall<DeleteSuccessResponse, DefaultError, DefaultDeletePayload>(deleteShopCall, true)

  const deleteShop = async (id: number) => {
    try {
      await deleteShopAction({ id })
    } catch {
      throw deleteShopError.value?.data
    }
  }

  return {
    shops,
    shopItem,
    isShopsLoading,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    getShops,
    getShopItem,
    applySort,
    deleteShop,
  }
})
