<template>
  <div class="info-page">
    <div class="info-page__wrapper">
      <span>Наименование страницы</span>
      <p @click="$emit('edit', page)">
        {{ page.title }}
      </p>
    </div>
    <div class="info-page__wrapper">
      <span>Активность</span>
      <ToggleElement
        fieldKey="isActive"
        :value="!page.isActive"
        :checked="!!page.isActive"
        @update:value="$emit('toggleIsActive', page.id, !page.isActive)"
      />
    </div>
    <DeleteIconBtn
      class="info-page__remove-btn"
      @click="$emit('delete', page.id)"
    />
  </div>
</template>

<script lang="ts" src="./HomeInfoPage.ts"></script>

<style scoped lang="scss" src="./HomeInfoPage.scss"></style>
