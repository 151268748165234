<template>
  <div class="date-picker">
    <label>
      <slot name="label" />
      <VueDatePicker
        v-model="date"
        v-bind="$attrs"
        auto-apply
        locale="ru"
        cancelText="Отмена"
        selectText="Выбрать"
        :class="{ 'only-past': onlyPast }"
      />
    </label>
  </div>
</template>

<script lang="ts" src="./DatePicker.ts"></script>

<style scoped lang="scss" src="./DatePicker.scss"></style>
