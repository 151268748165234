import axios from 'axios'
import type {
  GetMainPageDataResponse,
  UpdateMainPageDataPayload,
  GetMainPageBlockTemplatesResponse,
  GetMainPageBlocksResponse,
  CreateMainPageBlockPayload,
  UpdateMainPageBlockPayload,
  CreateUpdateMainPageBlockResponse,
  AddMainPageBlockProductPayload,
  UpdateMainPageBlockProductPayload,
  AddUpdateMainPageBlockProductResponse,
  GetMainPagePromoResponse,
  DeleteMainPageBlockPagePayload,
  AddMainPagePromoPayload,
  UpdateMainPagePromoPayload,
  AddUpdateMainPagePromoResponse,
  UploadMainPagePromoImagesPayload,
} from '@/types/mainPage'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const getMainPageDataApiCall = async (
  abortController?: AbortController,
): Promise<GetMainPageDataResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/mainpage', {
    signal: abortController?.signal,
  })
  return data
}

export const updateMainPageDataApiCal = async (
  abortController?: AbortController,
  params?: UpdateMainPageDataPayload,
): Promise<GetMainPageDataResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/mainpage',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getMainPageBlockTemplatesApiCall = async (
  abortController?: AbortController,
): Promise<GetMainPageBlockTemplatesResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/mainpage/templates', {
    signal: abortController?.signal,
  })
  return data
}

export const getMainPageBlocksApiCall = async (
  abortController?: AbortController,
): Promise<GetMainPageBlocksResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/mainpage/blocks', {
    signal: abortController?.signal,
  })
  return data
}

export const createMainPageBlockApiCall = async (
  abortController?: AbortController,
  params?: CreateMainPageBlockPayload,
): Promise<CreateUpdateMainPageBlockResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/mainpage/blocks',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const updateMainPageBlockApiCall = async (
  abortController?: AbortController,
  params?: UpdateMainPageBlockPayload,
): Promise<CreateUpdateMainPageBlockResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/mainpage/blocks/${params?.id}`,
    {
      ...params?.data,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteMainPageBlockApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/mainpage/blocks/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const addMainPageBlockProductApiCall = async (
  abortController?: AbortController,
  params?: AddMainPageBlockProductPayload,
): Promise<AddUpdateMainPageBlockProductResponse> => {
  const { data } = await axios.post(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/products`,
    {
      product_id: params?.productId,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const updateMainPageBlockProductApiCall = async (
  abortController?: AbortController,
  params?: UpdateMainPageBlockProductPayload,
): Promise<AddUpdateMainPageBlockProductResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/products/${params?.pageId}`,
    {
      product_id: params?.data.productId,
      is_active: params?.data.isActive,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteMainPageBlockProductApiCall = async (
  abortController?: AbortController,
  params?: DeleteMainPageBlockPagePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/products/${params?.pageId}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getMainPageBlockPromoApiCall = async (
  abortController?: AbortController,
  params?: DeleteMainPageBlockPagePayload,
): Promise<GetMainPagePromoResponse> => {
  const { data } = await axios.get(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/promos/${params?.pageId}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const addMainPageBlockPromoApiCall = async (
  abortController?: AbortController,
  params?: AddMainPagePromoPayload,
): Promise<AddUpdateMainPagePromoResponse> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/promos`,
    params?.data,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const updateMainPageBlockPromoApiCall = async (
  abortController?: AbortController,
  params?: UpdateMainPagePromoPayload,
): Promise<AddUpdateMainPagePromoResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/promos/${params?.promoId}`,
    {
      title: params?.data.title,
      description: params?.data.description,
      category_id: params?.data.categoryId,
      is_active: params?.data.isActive,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const uploadMainPageBlockPromoImagesApiCall = async (
  abortController?: AbortController,
  params?: UploadMainPagePromoImagesPayload,
): Promise<unknown> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/promos/${params?.promoId}`,
    params?.data,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteMainPageBlockPromoApiCall = async (
  abortController?: AbortController,
  params?: DeleteMainPageBlockPagePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/mainpage/blocks/${params?.blockId}/promos/${params?.pageId}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}
