<template>
  <div class="select">
    <slot name="title">
      <span v-if="title" class="select__title" :class="[titleClass]">{{
        title
      }}</span>
    </slot>
    <Multiselect
      id="selected"
      v-model="selected"
      :options="options"
      :label="label"
      :searchable="searchable"
      :clear-on-select="false"
      :allow-empty="allowEmpty"
      :placeholder="placeholder"
      :close-on-select="closeOnSelect"
      :show-labels="false"
      :multiple="multiple"
      :option-height="104"
      :track-by="trackBy"
      :disabled="disabled"
      :class="{ icon: label === 'icon' }"
      v-bind="$attrs"
    >
      <template #noOptions>
        <span>Список пуст</span>
      </template>
      <template v-if="isRequired" #placeholder>
        <span class="select__placeholder">{{ placeholder }}<span>*</span></span>
      </template>

      <template #noResult>
        <span>Не найдено</span>
      </template>

      <template #option="{ option }">
        <div class="select__option">
          <div v-if="label" class="select__wrapper">
            <img
              v-if="label === 'icon'"
              :src="option.icon || option.url"
              :alt="option.name"
              class="select__icon"
            >
            <span v-else>
              {{ option[label] }}
            </span>
          </div>
          <span v-else>{{ option }}</span>
          <SvgIcon v-if="multiple" icon="plus" />
        </div>
      </template>

      <template #caret="{ toggle }">
        <SvgIcon
          icon="chevron-down"
          class="caret"
          @mousedown.prevent.stop="toggle"
        />
      </template>

      <template #singleLabel="{ option }">
        <div v-if="label" class="select__wrapper">
          <img
            v-if="label === 'icon'"
            :src="option.icon || option.url"
            :label="option.name"
            class="select__icon"
          >
          <span v-else>
            {{ option[label] }}
          </span>
        </div>
        <span v-else>{{ option }}</span>
      </template>

      <template v-if="multiple" #selection="{ values }">
        <div v-if="label === 'interval'" class="multiselect__single">
          {{ intervalSelection(values) }}
        </div>
        <span v-else>{{ values }}</span>
      </template>
    </Multiselect>
    <div v-if="errorText" class="select__error">
      <NotifyComponent
        v-for="(error, index) in errorText"
        :key="index"
        :text="error"
        status="error"
      >
        {{ error }}
      </NotifyComponent>
    </div>
  </div>
</template>

<script lang="ts" src="./SelectElement.ts"></script>

<style lang="scss" src="./SelectElement.scss"></style>
