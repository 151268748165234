import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getMainPageBlockTemplatesApiCall,
  getMainPageBlocksApiCall,
  createMainPageBlockApiCall,
  updateMainPageBlockApiCall,
  deleteMainPageBlockApiCall,
  addMainPageBlockProductApiCall,
  updateMainPageBlockProductApiCall,
  deleteMainPageBlockProductApiCall,
  getMainPageBlockPromoApiCall,
  addMainPageBlockPromoApiCall,
  updateMainPageBlockPromoApiCall,
  uploadMainPageBlockPromoImagesApiCall,
  deleteMainPageBlockPromoApiCall,
} from '@/api/mainPage'
import type { RootState, RootGetters, RootActions } from './types'
import type {
  IMainPageBlock,
  IMainPagePromo,
  IBlockPage,
  GetMainPageBlockTemplatesResponse,
  GetMainPageBlocksResponse,
  CreateMainPageBlockPayload,
  UpdateMainPageBlockPayload,
  CreateUpdateMainPageBlockResponse,
  IMainPageProduct,
  AddMainPageBlockProductPayload,
  UpdateMainPageBlockProductPayload,
  AddUpdateMainPageBlockProductResponse,
  DeleteMainPageBlockPagePayload,
  GetMainPagePromoResponse,
  AddMainPagePromoPayload,
  UpdateMainPagePromoPayload,
  AddUpdateMainPagePromoResponse,
  UploadMainPagePromoImagesPayload,
} from '@/types/mainPage'
import type { DefaultError } from '@/types/httpError'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const useMainPageStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('mainPage', {
  state() {
    return {
      templates: null,
      blocks: null,
      openedPromo: null,
      error: null,
    }
  },
  getters: {},
  actions: {
    replaceBlock(id: number, body?: IMainPageBlock) {
      if (this.blocks) {
        if (body) {
          const index = this.blocks.findIndex((i) => i.id === id)
          this.blocks[index] = body
        } else {
          this.blocks = this.blocks.filter((i) => i.id !== id)
        }
      }
    },
    replaceChild(
      blockId: number,
      childId: number,
      child?: IMainPageProduct | IMainPagePromo,
    ) {
      if (this.blocks) {
        const parentBlockIndex = this.blocks.findIndex((b) => b.id === blockId)

        if (child) {
          const childIndex = this.blocks[parentBlockIndex].children.findIndex(
            (p) => p.id === childId,
          )

          this.blocks[parentBlockIndex].children[childIndex]
            = child as IBlockPage
          return
        }

        this.blocks[parentBlockIndex].children = this.blocks[
          parentBlockIndex
        ].children.filter((c) => c.id !== childId)
      }
    },
    async getTemplates() {
      const { data, executeApiCall, error } = useApiCall<
        GetMainPageBlockTemplatesResponse,
        DefaultError
      >(getMainPageBlockTemplatesApiCall, true)

      try {
        await executeApiCall()
        if (data.value) {
          this.templates = data.value.data
        }
      } catch {
        console.error(error)
      }
    },
    async getBlocks() {
      const { data, executeApiCall, error } = useApiCall<
        GetMainPageBlocksResponse,
        DefaultError
      >(getMainPageBlocksApiCall, true)

      try {
        await executeApiCall()
        if (data.value) {
          this.blocks = data.value.data
        }
      } catch {
        console.error(error)
      }
    },
    async createBlock(params: CreateMainPageBlockPayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateUpdateMainPageBlockResponse,
        DefaultError,
        CreateMainPageBlockPayload
      >(createMainPageBlockApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          if (!this.blocks) {
            this.blocks = []
          }

          this.blocks = [...this.blocks, data.value.data]
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async updateBlock(params: UpdateMainPageBlockPayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateUpdateMainPageBlockResponse,
        DefaultError,
        UpdateMainPageBlockPayload
      >(updateMainPageBlockApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceBlock(params.id, data.value.data)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async deleteBlock(params: DefaultDeletePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DefaultDeletePayload
      >(deleteMainPageBlockApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceBlock(params.id)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async addBlockProduct(params: AddMainPageBlockProductPayload) {
      const { data, executeApiCall, error } = useApiCall<
        AddUpdateMainPageBlockProductResponse,
        DefaultError,
        AddMainPageBlockProductPayload
      >(addMainPageBlockProductApiCall, true, params, false)

      try {
        await executeApiCall()

        if (data.value && this.blocks) {
          const parentBlockIndex = this.blocks.findIndex(
            (b) => b.id === params.blockId,
          )
          this.blocks[parentBlockIndex].children = [
            ...this.blocks[parentBlockIndex].children,
            data.value.data,
          ]
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async updateBlockProduct(params: UpdateMainPageBlockProductPayload) {
      const { data, executeApiCall, error } = useApiCall<
        AddUpdateMainPageBlockProductResponse,
        DefaultError,
        UpdateMainPageBlockProductPayload
      >(updateMainPageBlockProductApiCall, true, params, false)

      try {
        await executeApiCall()
        if (data.value) {
          const { blockId, pageId } = params
          this.replaceChild(blockId, pageId, data.value.data)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async deleteBlockProduct(params: DeleteMainPageBlockPagePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DeleteMainPageBlockPagePayload
      >(deleteMainPageBlockProductApiCall, true, params, false)

      try {
        await executeApiCall()
        if (data.value) {
          const { blockId, pageId } = params
          this.replaceChild(blockId, pageId)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async getBlockPromo(params: DeleteMainPageBlockPagePayload) {
      const { data, executeApiCall, error } = useApiCall<
        GetMainPagePromoResponse,
        DefaultError,
        DeleteMainPageBlockPagePayload
      >(getMainPageBlockPromoApiCall, true, params, false)

      try {
        await executeApiCall()
        if (data.value) {
          this.openedPromo = data.value.data
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async addBlockPromo(params: AddMainPagePromoPayload) {
      const { executeApiCall, error } = useApiCall<
        AddUpdateMainPagePromoResponse,
        DefaultError,
        AddMainPagePromoPayload
      >(addMainPageBlockPromoApiCall, true, params, false)

      try {
        await executeApiCall()
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async updateBlockPromo(params: UpdateMainPagePromoPayload) {
      const { data, executeApiCall, error } = useApiCall<
        AddUpdateMainPagePromoResponse,
        DefaultError,
        UpdateMainPagePromoPayload
      >(updateMainPageBlockPromoApiCall, true, params, false)

      try {
        await executeApiCall()

        if (data.value && this.blocks) {
          const { blockId, promoId } = params
          this.replaceChild(blockId, promoId, data.value.data)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async uploadBlockPromoImages(params: UploadMainPagePromoImagesPayload) {
      const { executeApiCall, error } = useApiCall<
        unknown,
        DefaultError,
        UploadMainPagePromoImagesPayload
      >(uploadMainPageBlockPromoImagesApiCall, true, params, false)

      try {
        await executeApiCall()
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
    async deleteBlockPromo(params: DeleteMainPageBlockPagePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DeleteMainPageBlockPagePayload
      >(deleteMainPageBlockPromoApiCall, true, params, false)

      try {
        await executeApiCall()
        if (data.value) {
          const { blockId, pageId } = params
          this.replaceChild(blockId, pageId)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
          console.error(error.value?.data)
        }
        throw error.value?.data
      }
    },
  },
})
