<template>
  <form class="info-form" @submit.prevent="submitHandler">
    <fieldset class="info-form__fieldset">
      <FormElement
        v-model:value.trim="fields.title"
        title="Наименование информационного блока"
        titleClass="black-18"
        placeholder="Введите наименование"
        :errorText="errorFields?.title"
      />
      <SelectElement
        v-if="!isEditing"
        :value="templates[0]"
        :options="templates"
        objectKey="template"
        label="nameRu"
        trackBy="name"
        title="Используемый шаблон"
        titleClass="medium-18"
        :errorText="errorFields?.template"
        @dataChanged="onValueUpdate"
      />
      <CheckboxRadioElement
        label="Активен"
        fieldKey="isActive"
        :value="!fields.isActive"
        :checked="!!fields.isActive"
        @update:value="onValueUpdate"
      />
      <NotifyComponent v-if="!!error" :text="error" />
    </fieldset>
    <FormActionBtns
      reversed
      :isLoading="isLoading"
      :disabled="!fields.title"
      @cancel="$emit('close')"
    >
      {{ isEditing ? 'Сохранить  изменения' : 'Создать блок' }}
    </FormActionBtns>
  </form>
</template>

<script lang="ts" src="./HomeInfoForm.ts"></script>

<style scoped lang="scss" src="./HomeInfoForm.scss"></style>
