import axios from 'axios'
import type {
  GetBannersResponse,
  CreateUpdateBannerResponse,
  UpdateBannerPayload,
  UpdateBannerImagePayload,
} from '@/types/banners'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const getBannersApiCall = async (
  abortController?: AbortController,
): Promise<GetBannersResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/banners', {
    signal: abortController?.signal,
  })
  return data
}

export const createBannerApiCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<CreateUpdateBannerResponse> => {
  const { data } = await axios.postForm('/dashboard/v1/chief/banners', params, {
    signal: abortController?.signal,
  })
  return data
}

export const updateBannerApiCall = async (
  abortController?: AbortController,
  params?: UpdateBannerPayload,
): Promise<CreateUpdateBannerResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/banners/${params?.id}`,
    params?.data,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const updateBannerImageApiCall = async (
  abortController?: AbortController,
  params?: UpdateBannerImagePayload,
): Promise<CreateUpdateBannerResponse> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/banners/${params?.id}`,
    params?.data,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteBannerApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/banners/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}
