import { defineComponent } from 'vue'
import FileLoader from '@/components/FileLoader/FileLoader.vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'LoaderWithPreview',
  components: {
    FileLoader,
  },
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    preview: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '.jpg,.png',
    },
    text: {
      type: String,
    },
    secondaryText: {
      type: String,
    },
    errorText: {
      type: Array as PropType<string[]>,
    },
  },
  emits: ['remove', 'load'],
  setup() {
    return {}
  },
})
