export const REGISTRATION_TYPE = {
  phone: 'СМС',
  email: 'E-mail',
  email_phone: 'E-mail + CМС',
}

export const AUTH_TYPE = {
  0: 'Не требовать код из смс',
  1: 'Требовать код из смс',
}

export const LOYALTY_STATUS = {
  on: {
    text: 'Вкл',
    style: 'green',
  },
  off: {
    text: 'Выкл',
    style: 'red',
  },
}

export const LOYALTY_PLACEHOLDERS = {
  delay: 'Введите отсрочку начисления в часах',
  rate: 'Введите бонусы в 1 рубле',
  annualAmount: 'Введите ежегодные бонусы',
  registerAmount: 'Введите бонусы за регистрацию',
  birthdayAmount: 'Введите бонусы на день рождения',
  threshold: 'Введите пороговую сумму',
  bonusExpirationDays: 'Введите срок активности бонусов',
  percent: 'Введите процент бонусов с покупки',
  buyPercent: 'Введите процент оплаты бонусами',
}

export const LOYALTY_TABLE_HEADINGS = [
  { text: 'Название', value: 'name' },
  { text: 'Порог, ₽', value: 'threshold' },
  {
    text: 'Срок активности бонусов, дн.',
    value: 'bonusExpirationDays',
  },
  { text: 'Процент бонусов с покупки', value: 'percent' },
  { text: 'Процент оплаты бонусами', value: 'buyPercent' },
]

export const STORE_SETTINGS_STATUSES_HEADINGS = [
  { text: 'Наименование статуса', value: 'name' },
  { text: 'Тип статуса', value: 'code' },
  { text: 'Внешний ID ', value: 'extId' },
  {
    text: 'Активность',
    value: 'active',
  },
]

export const STORE_SETTINGS_PRODUCTS_HEADINGS = [
  { text: 'Наименование', value: 'name' },
  { text: 'Обозначение', value: 'abbr' },
  { text: 'Тип', value: 'type' },
  // { text: 'Активность', value: 'active' },
]

export const PAYMENT_SETTINGS_TAX_NUMBER_OPTIONS = [
  {
    text: 'без НДС. Тег 1105',
    value: 'none',
  },
  {
    text: 'НДС по ставке 0%. Тег 1104',
    value: 'vat0',
  },
  {
    text: 'НДС чека по ставке 10%. Тег 1103',
    value: 'vat10',
  },
  {
    text: 'НДС чека по расчетной ставке 10/110. Тег 1107',
    value: 'vat110',
  },
  {
    text: 'НДС чека по ставке 20%. Тег 1102',
    value: 'vat20',
  },
  {
    text: 'НДС чека по расчетной ставке 20/120. Тег 1106',
    value: 'vat120',
  },
]

export const PAYMENT_SETTINGS_TAX_SYSTEM_OPTIONS = [
  {
    text: 'общая СН',
    value: 'osn',
  },
  {
    text: 'упрощенная СН (доходы)',
    value: 'usn_income',
  },
  {
    text: 'упрощенная СН (доходы минус расходы)',
    value: 'usn_income_outcome',
  },
  {
    text: 'единый налог на вмененный доход',
    value: 'envd',
  },
  {
    text: 'единый сельскохозяйственный налог',
    value: 'esn',
  },
  {
    text: 'патентная СН',
    value: 'patent',
  },
]

export const DELIVERY_SETTINGS_TABLE_HEADINGS = [
  { text: 'Наименование способа', value: 'name' },
  { text: 'Тип доставки', value: 'type' },
  { text: 'Стоимость, ₽', value: 'baseCost' },
  { text: 'Ограничения', value: 'limits' },
  { text: 'Активность', value: 'active' },
]

export const DELIVERY_SETTINGS_FORM_TABS = [
  {
    title: 'Основные данные',
    value: 'main',
  },
  {
    title: 'Интервалы',
    value: 'intervals',
  },
  {
    title: 'Ограничения',
    value: 'limits',
  },
]

export const BOTTOM_NAV_BAR_HEADINGS = {
  home: 'Стартовая',
  market: 'Магазин',
  workers: 'Персонал',
  menu: 'Личный кабинет/Меню',
  cart: 'Корзина',
  favorite: 'Избранное',
  catalog_market: 'Услуги',
  catalog: 'Каталог',
}
