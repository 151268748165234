import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import { useAppStore } from '../app/app'
import {
  addLoyaltyBonusLevelApiCall,
  changeLoyaltyBonusLevelApiCall,
  changeLoyaltySettingsApiCall,
  changeUserAuthSettingsApiCall,
  deleteLoyaltyBonusLevelApiCall,
  getLoyaltySettingsApiCall,
  getStoreStatusCodesApiCall,
  getStoreStatusesApiCall,
  getStoreStatusesForSelectApiCall,
  toggleStoreActivityApiCall,
  createOrderStatusApiCall,
  changeOrderStatusApiCall,
  deleteOrderStatusApiCall,
  getStoreProductDetailsApiCall,
  createStoreProductDetailApiCall,
  editStoreProductDetailApiCall,
  deleteStoreProductDetailApiCall,
  getAcquiringApiCall,
  updateAcquiringApiCall,
} from '@/api/settings'
import {
  PAYMENT_SETTINGS_TAX_NUMBER_OPTIONS,
  PAYMENT_SETTINGS_TAX_SYSTEM_OPTIONS,
} from '@/constants/settings'
import type { RootState, RootGetters, RootActions } from './types'
import type { DefaultError, AltError } from '@/types/httpError'
import type { IPageQuery, MakeOptional, DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'
import type {
  ChangeUserAuthSettingsPayload,
  BonusLoyaltyResponse,
  GetOrderStatusesResponse,
  GetOrderStatusesCodes,
  GetOrderStatusesForSelectResponse,
  LoyaltyChangePayload,
  AddLoyaltyBonusLevelPayload,
  ChangeLoyaltyBonusLevelPayload,
  ToggleStoreActivityPayload,
  CreateOrderStatusPayload,
  ChangeOrderStatusPayload,
  ChangeOrderStatusResponse,
  IOrderStatus,
  GetProductDetailsResponse,
  CreateProductDetailPayload,
  EditProductDetailPayload,
  CreateEditProductDetailResponse,
  IBonusLevel,
  IProductDetails,
  IAcquiring,
  AcquiringResponse,
  UpdateAcquiringPayload,
} from '@/types/settings'
import type { GetAppDataSuccessResponse } from '@/types/app'

export const useSettingsStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('settings', {
  state() {
    return {
      loyaltySettings: null,
      storeStatuses: null,
      storeStatusesForSelect: null,
      storeStatusCodes: null,
      productsDetails: null,
      acquiring: null,
      deliveries: null,
      selectedDelivery: null,
      deliveryFormOptions: {
        icons: null,
        types: null,
      },
      error: null,
    }
  },
  getters: {
    getLoyaltySettingsState(state) {
      return state.loyaltySettings
    },
    getProductDetailFromState(state) {
      return (id: number) =>
        state.productsDetails?.data.find((i) => i.id === id)
    },
  },
  actions: {
    setError(error: DefaultError | AltError) {
      this.error = error
    },
    replaceBonusLevelItem(id: number, body?: IBonusLevel) {
      if (this.loyaltySettings && this.loyaltySettings.bonus?.levels) {
        if (body) {
          const index = this.loyaltySettings?.bonus?.levels.findIndex(
            (i) => i.id === id,
          )
          this.loyaltySettings.bonus.levels[index] = body
        } else {
          this.loyaltySettings.bonus.levels
            = this.loyaltySettings.bonus.levels.filter((i) => i.id !== id)
        }
      }
    },
    setStatusesWithReplace(
      shouldBeReplaced: boolean,
      data: GetOrderStatusesResponse['data'],
    ) {
      const modifiedData = data.data.map((item) => ({
        ...item,
        extId: item.extId ? String(item.extId) : '',
      }))

      if (!shouldBeReplaced && this.storeStatuses) {
        this.storeStatuses = {
          data: [...this.storeStatuses?.data, ...data.data],
          pagination: data.pagination,
        }
      } else {
        this.storeStatuses = {
          data: modifiedData,
          pagination: data.pagination,
        }
      }
    },
    replaceStatusItem(id: number, body?: IOrderStatus) {
      if (this.storeStatuses) {
        if (body) {
          const index = this.storeStatuses.data.findIndex((i) => i.id === id)
          this.storeStatuses.data[index] = body
        } else {
          this.storeStatuses.data = this.storeStatuses.data.filter(
            (i) => i.id !== id,
          )
        }
      }
    },
    replaceProductDetail(id: number, body?: IProductDetails) {
      if (this.productsDetails) {
        if (body) {
          const index = this.productsDetails.data.findIndex((i) => i.id === id)
          this.productsDetails.data[index] = body
        } else {
          this.productsDetails.data = this.productsDetails.data.filter(
            (i) => i.id !== id,
          )
        }
      }
    },
    setAcquiring(body: IAcquiring<string>) {
      const { id, name, ffdKeys, keys, ...rest } = body
      const vatObj = (val: string) =>
        PAYMENT_SETTINGS_TAX_NUMBER_OPTIONS.find((i) => i.value === val) ?? ''
      const snoObj = (val: string) =>
        PAYMENT_SETTINGS_TAX_SYSTEM_OPTIONS.find((i) => i.value === val) ?? ''

      const modifiedFfdKeys = {
        inn: ffdKeys?.inn ?? '',
        paymentAddress: ffdKeys?.paymentAddress ?? '',
        vat: ffdKeys?.vat ? vatObj(ffdKeys?.vat) : '',
        sno: ffdKeys?.sno ? snoObj(ffdKeys?.sno) : '',
      }

      this.acquiring = {
        id,
        data: {
          ...rest,
          ...keys,
          ...modifiedFfdKeys,
        },
      }
    },
    async changeUserAuthSettings(params: ChangeUserAuthSettingsPayload) {
      const {
        data: changedAppData,
        executeApiCall: changeAppDataAction,
        error: changedAppError,
      } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        ChangeUserAuthSettingsPayload
      >(changeUserAuthSettingsApiCall, true, params)
      const appStore = useAppStore()

      try {
        await changeAppDataAction()
        if (changedAppData.value) {
          appStore.setMainAppData(changedAppData.value.data)
        }
      } catch {
        if (changedAppError.value) {
          this.setError(changedAppError.value.data)
          throw changedAppError.value.data
        }
      }
    },
    async getLoyaltySettings() {
      const {
        data: loyaltySettingsData,
        executeApiCall: loyaltySettingsAction,
        error: loyaltySettingsError,
      } = useApiCall<BonusLoyaltyResponse, DefaultError>(
        getLoyaltySettingsApiCall,
        true,
      )

      try {
        await loyaltySettingsAction()
        if (loyaltySettingsData.value) {
          this.loyaltySettings = loyaltySettingsData.value.data
        }
      } catch {
        if (loyaltySettingsError.value) {
          this.setError(loyaltySettingsError.value.data)
        }
      }
    },
    async changeLoyaltySettings(params: Partial<LoyaltyChangePayload>) {
      const {
        data: loyaltyData,
        executeApiCall: changeLoyaltySettings,
        error: loyaltyError,
      } = useApiCall<
        BonusLoyaltyResponse,
        DefaultError,
        Partial<LoyaltyChangePayload>
      >(changeLoyaltySettingsApiCall, true, params)

      try {
        await changeLoyaltySettings()

        if (loyaltyData.value) {
          this.loyaltySettings = loyaltyData.value.data
        }
      } catch {
        if (loyaltyError.value) {
          this.setError(loyaltyError.value.data)
          throw loyaltyError.value.data
        }
      }
    },
    async addLoyaltyBonusLevel(params: AddLoyaltyBonusLevelPayload) {
      const { data, executeApiCall, error } = useApiCall<
        BonusLoyaltyResponse,
        DefaultError,
        AddLoyaltyBonusLevelPayload
      >(addLoyaltyBonusLevelApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.loyaltySettings = data.value.data
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async changeLoyaltyBonusLevel(params: ChangeLoyaltyBonusLevelPayload) {
      const { data, executeApiCall, error } = useApiCall<
        BonusLoyaltyResponse,
        DefaultError,
        ChangeLoyaltyBonusLevelPayload
      >(changeLoyaltyBonusLevelApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.loyaltySettings = data.value.data
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async deleteLoyaltyBonusLevel(params: DefaultDeletePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DefaultDeletePayload
      >(deleteLoyaltyBonusLevelApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceBonusLevelItem(params.id)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async getStoreStatuses(page?: number) {
      const {
        data: storeStatuses,
        executeApiCall: storeStatusesAction,
        error: storeStatusesError,
      } = useApiCall<
        GetOrderStatusesResponse,
        DefaultError,
        MakeOptional<IPageQuery, 'page'>
      >(getStoreStatusesApiCall, true, { page })

      try {
        await storeStatusesAction()
        if (storeStatuses.value && storeStatuses.value.data.data.length) {
          if (typeof page === 'number' && this.storeStatuses) {
            this.setStatusesWithReplace(false, storeStatuses.value.data)
            return
          }
          this.setStatusesWithReplace(true, storeStatuses.value.data)
        }
      } catch {
        if (storeStatusesError.value) {
          this.setError(storeStatusesError.value.data)
        }
      }
    },
    async loadMoreStoreStatuses(page: number) {
      if (
        this.storeStatuses
        && page <= this.storeStatuses?.pagination.totalPages
      ) {
        await this.getStoreStatuses(page)
      }
    },
    async getStoreStatusesForSelect() {
      const {
        data: storeStatuses,
        executeApiCall: storeStatusesAction,
        error: storeStatusesError,
      } = useApiCall<GetOrderStatusesForSelectResponse, DefaultError>(
        getStoreStatusesForSelectApiCall,
        true,
      )

      try {
        await storeStatusesAction()
        if (storeStatuses.value) {
          this.storeStatusesForSelect = storeStatuses.value.data
        }
      } catch {
        if (storeStatusesError.value) {
          throw storeStatusesError.value.data
        }
      }
    },
    async getStoreStatusCodes() {
      const {
        data: storeStatusCodes,
        executeApiCall: storeStatusCodesAction,
        error: storeStatusCodesError,
      } = useApiCall<GetOrderStatusesCodes, DefaultError>(
        getStoreStatusCodesApiCall,
        true,
      )

      try {
        await storeStatusCodesAction()
        if (storeStatusCodes.value) {
          this.storeStatusCodes = storeStatusCodes.value.data
        }
      } catch {
        if (storeStatusCodesError.value) {
          this.setError(storeStatusCodesError.value.data)
        }
      }
    },
    async changeStoreActivity(params: ToggleStoreActivityPayload) {
      const { data, executeApiCall, error } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        ToggleStoreActivityPayload
      >(toggleStoreActivityApiCall, true, params)
      const appStore = useAppStore()

      try {
        await executeApiCall()
        if (data.value) {
          appStore.setMainAppData(data.value.data)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async createStoreStatusItem(params: CreateOrderStatusPayload) {
      const { data, executeApiCall, error } = useApiCall<
        ChangeOrderStatusResponse,
        AltError,
        CreateOrderStatusPayload
      >(createOrderStatusApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value && this.storeStatuses) {
          const item = data.value.data
          this.storeStatuses.data = [
            ...this.storeStatuses.data,
            { ...item, extId: item.extId ? String(item.extId) : '' },
          ]
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async changeStoreStatusItem(params: ChangeOrderStatusPayload) {
      const { data, executeApiCall, error } = useApiCall<
        ChangeOrderStatusResponse,
        AltError,
        ChangeOrderStatusPayload
      >(changeOrderStatusApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          const { id, ...rest } = data.value.data
          this.replaceStatusItem(id, { id, ...rest })
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async deleteStoreStatusItem(params: DefaultDeletePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DefaultDeletePayload
      >(deleteOrderStatusApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceStatusItem(params.id)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
        }
      }
    },
    async getStoreProductsDetails(page?: number) {
      const {
        data: storeProductsDetailsData,
        executeApiCall: getStoreProductsDetailsAction,
        error: storeProductsDetailsError,
      } = useApiCall<
        GetProductDetailsResponse,
        DefaultError,
        MakeOptional<IPageQuery, 'page'>
      >(getStoreProductDetailsApiCall, true, { page })

      try {
        await getStoreProductsDetailsAction()
        const res = storeProductsDetailsData.value
        if (res && res.data.data.length) {
          if (typeof page === 'number' && this.productsDetails) {
            this.productsDetails = {
              data: [...this.productsDetails?.data, ...res.data.data],
              pagination: res.data.pagination,
            }
            return
          }
          this.productsDetails = res.data
        }
      } catch {
        if (storeProductsDetailsError.value) {
          this.setError(storeProductsDetailsError.value.data)
        }
      }
    },
    async loadMoreStoreProductsDetails(page: number) {
      if (
        this.productsDetails
        && page <= this.productsDetails?.pagination.totalPages
      ) {
        await this.getStoreProductsDetails(page)
      }
    },
    async createProductDetail(params: CreateProductDetailPayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateEditProductDetailResponse,
        AltError,
        CreateProductDetailPayload
      >(createStoreProductDetailApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value && this.productsDetails) {
          this.productsDetails.data = this.productsDetails
            ? [...this.productsDetails.data, data.value.data]
            : [data.value.data]
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async editProductDetail(params: EditProductDetailPayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateEditProductDetailResponse,
        AltError,
        EditProductDetailPayload
      >(editStoreProductDetailApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceProductDetail(params.id, data.value.data)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async deleteProductDetail(params: DefaultDeletePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        AltError,
        DefaultDeletePayload
      >(deleteStoreProductDetailApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceProductDetail(params.id)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
    async getAcquiring() {
      const { data, executeApiCall, error } = useApiCall<
        AcquiringResponse,
        DefaultError
      >(getAcquiringApiCall, true)

      try {
        await executeApiCall()
        if (data.value) {
          this.setAcquiring(data.value.data)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
        }
      }
    },
    async updateAcquiring(params: UpdateAcquiringPayload) {
      const { data, executeApiCall, error } = useApiCall<
        AcquiringResponse,
        DefaultError,
        UpdateAcquiringPayload
      >(updateAcquiringApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.setAcquiring(data.value.data)
        }
      } catch {
        if (error.value) {
          this.setError(error.value.data)
          throw error.value.data
        }
      }
    },
  },
})
