import type { Client, ClientsPayload, ClientsResponse, ClientItemPayload, ClientItemResponse } from '@/types/clients'
import type { DefaultError } from '@/types/httpError'
import type { ItemPayload } from '@/types/main'
import { getClientsCall, getClientItemCall, editClientItemCall, getClientsForSelectCall } from '@/api/clients'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { useFilterStore } from '@/store/filter'

export const useClientsStore = defineStore('clients', () => {
  const clients = ref<Client[]>([])
  const clientsForSelect = ref<Client[]>([])
  const openedClient = ref<Client | null>(null)
  const filterStore = useFilterStore()
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as ClientsPayload

  const statuses = {
    Базовый: 'info',
    Бонусный: 'success',
    Продвинутый: 'secondary',
  }

  const getStatus = (status: string) => {
    return statuses[status as keyof typeof statuses]
  }

  const {
    data: clientsData,
    executeApiCall: clientsAction,
    isLoading: clientsLoading,
    error: clientsError,
  } = useApiCall<ClientsResponse, DefaultError, ClientsPayload>(
    getClientsCall,
    true,
  )

  const { data: clientsForSelectData, executeApiCall: clientsForSelectAction, error: clientsForSelectError } = useApiCall<Client[], DefaultError, ClientsPayload>(
    getClientsForSelectCall,
    true,
  )

  const isClientsLoading = computed(() => clientsLoading.value)

  const changePage = async (page: number) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    await getClients()
  }

  const clearFilter = async () => {
    clearQuery()
    await applyFilter(filterFields)
  }

  const applyFilter = async (filters: ClientsPayload) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key as keyof ClientsPayload] || filters[key as keyof ClientsPayload] === false) {
        filterFields[key as keyof ClientsPayload] = filters[key as keyof ClientsPayload]
      }
      else {
        filterFields[key as keyof ClientsPayload] = ''
      }
    })
    filterFields.page = 1
    await getClients()
  }

  watch(() => filterStore.isSend, async () => {
    if (filterStore.isSend) {
      await applyFilter({
        search: filterStore.filters.search,
      })
      filterStore.isSend = false
    }
  })

  const getClients = async () => {
    try {
      const filter = {} as ClientsPayload
      Object.keys(filterFields).forEach((key) => {
        if (filterFields[key as keyof ClientsPayload] || filterFields[key as keyof ClientsPayload] === false) {
          filter[key as keyof ClientsPayload] = filterFields[key as keyof ClientsPayload]
        }
      })
      await clientsAction(filter)
      if (clientsData.value) {
        clients.value = [...clientsData.value.data]
        metaData.total = clientsData.value.pagination.total
        metaData.totalPages = clientsData.value.pagination.totalPages
      }
    }
    catch {
      console.error(clientsError.value)
    }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    Object.keys(filterFields).forEach(n => ['page', 'perPage'].includes(n) || delete filterFields[n])
  }

  const getClientsForSelect = async () => {
    try {
      await clientsForSelectAction()
      if (clientsForSelectData.value) {
        clientsForSelect.value = clientsForSelectData.value
      }
    } catch {
      throw clientsForSelectError.value?.data
    }
  }

  const {
    data: clientItemData,
    executeApiCall: getClientItemAction,
    error: clientItemError,
  } = useApiCall<ClientItemResponse, DefaultError, ItemPayload>(
    getClientItemCall,
    true,
  )

  const getClientItem = async (id: number) => {
    try {
      await getClientItemAction({ id })
      if (clientItemData.value) {
        openedClient.value = clientItemData.value.data
      }
    } catch {
      throw clientItemError.value?.data
    }
  }

  const {
    isLoading: isEditClientItemLoading,
    executeApiCall: editClientItemAction,
    error: editClientItemError,
  } = useApiCall<ClientItemResponse, DefaultError, ClientItemPayload>(
    editClientItemCall,
    true,
  )

  const editClientItem = async (params: ClientItemPayload) => {
    try {
      await editClientItemAction(params)
    } catch {
      throw editClientItemError.value?.data
    }
  }

  return {
    clients,
    clientsForSelect,
    openedClient,
    isClientsLoading,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    getClients,
    getClientItem,
    getStatus,
    isEditClientItemLoading,
    editClientItem,
    getClientsForSelect,
  }
})
