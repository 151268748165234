import {
  defineComponent,
  ref,
  reactive,
  watch,
  computed,
  onBeforeMount,
} from 'vue'
import { useMainPageStore } from '@/store/mainPage'
import type {
  IMainPageBlock,
  IMainPageBlockTemplate,
  CreateMainPageBlockPayload,
  UpdateMainPageBlockPayload,
} from '@/types/mainPage'
import type { PropType } from 'vue'
import type { DefaultError } from '@/types/httpError'

export default defineComponent({
  name: 'HomeInfoForm',
  props: {
    block: {
      type: Object as PropType<IMainPageBlock>,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isEditing = ref(false)
    const blockId = ref<number | ''>('')
    const fields = reactive({
      title: '',
      template: {
        nameRu: '',
        name: '',
      },
      isActive: true,
    })
    const isLoading = ref(false)
    const error = ref('')
    const errorFields = ref<Record<string, string[]> | null>(null)
    const mainPageStore = useMainPageStore()

    const templates = computed<IMainPageBlockTemplate[]>(
      () => mainPageStore.templates ?? [],
    )

    const getTemplate = (value: string) =>
      templates.value.find((i) => i.name === value)

    watch(
      () => props.block,
      (newVal) => {
        if (newVal) {
          isEditing.value = true
          const { id, template, ...rest } = newVal
          blockId.value = id

          Object.assign(fields, {
            ...rest,
            canvas: getTemplate(template),
          })
        }
      },
      {
        immediate: true,
      },
    )

    const onValueUpdate = (
      key: keyof typeof fields,
      value: { nameRu: string, name: string } | boolean,
    ) => {
      if (key === 'template' && typeof value !== 'boolean') {
        fields[key] = value
      }
      if (key === 'isActive') {
        fields[key] = value as boolean
      }
    }

    const resetErrors = () => {
      error.value = ''
      errorFields.value = null
      mainPageStore.error = null
    }

    const handleCreateMainPageBlock = async () => {
      isLoading.value = true
      resetErrors()

      const { title, template, isActive } = fields
      const body: CreateMainPageBlockPayload = {
        title,
        template: template.name,
        isActive: isActive ? 1 : 0,
      }

      await mainPageStore.createBlock(body)
      isLoading.value = false

      if (mainPageStore.error) {
        error.value = (mainPageStore.error as DefaultError).error
        errorFields.value = (mainPageStore.error as DefaultError).data
        return
      }
      emit('close')
    }

    const handleUpdateMainPageBlock = async () => {
      resetErrors()
      isLoading.value = true
      const { title, isActive } = fields
      const body: UpdateMainPageBlockPayload = {
        id: +blockId.value,
        data: {
          title,
          isActive,
        },
      }

      await mainPageStore.updateBlock(body)
      isLoading.value = false

      if (mainPageStore.error) {
        error.value = (mainPageStore.error as DefaultError).error
        errorFields.value = (mainPageStore.error as DefaultError).data
        return
      }
      emit('close')
    }

    const submitHandler = computed(() =>
      isEditing.value ? handleUpdateMainPageBlock : handleCreateMainPageBlock,
    )

    onBeforeMount(async () => {
      if (!isEditing.value) {
        await mainPageStore.getTemplates()
      }
    })

    return {
      isEditing,
      fields,
      templates,
      isLoading,
      error,
      errorFields,
      onValueUpdate,
      submitHandler,
    }
  },
})
