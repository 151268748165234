import MenuComponent from '@/components/MenuComponent/MenuComponent.vue'
import FilterComponent from '@/components/ui/FilterComponent/FilterComponent.vue'
import { useAuthStore } from '@/store/auth/auth'
import { Breakpoints } from '@/enum/Breakpoints'
import { setSessionStorageItem } from '@/helpers/sessionStorage'
import { useUIStore } from '@/store/ui/ui'
import { useCompanyStore } from '@/store/company'
import { onClickOutside } from '@vueuse/core'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  onBeforeMount,
  ref,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { MaybeElementRef } from '@vueuse/core'
import type { MenuItem } from '@/types/lists'

export default defineComponent({
  name: 'MainLayout',
  components: {
    MenuComponent,
    FilterComponent,
  },
  setup() {
    const UIStore = useUIStore()
    const route = useRoute()
    const router = useRouter()
    const authStore = useAuthStore()
    const companyStore = useCompanyStore()
    const menuRef = ref<
      (MaybeElementRef & { isConfirmPopupOpen?: boolean }) | null
    >(null)

    const userMenuItems: MenuItem[] = [
      {
        title: 'Личный кабинет',
        route: '/profile',
      },
      {
        title: 'Выйти',
        color: 'red',
        callback: 'handleSignOut',
      },
    ]
    const notebookBreakpoint = Breakpoints.NOTEBOOK

    const handleSignOut = async () => {
      try {
        await authStore.logout()
        await router.push('/login')
      } catch (error) {
        console.error(error)
      }
    }
    const isFilter = computed(() =>
      [
        'catalogCategories',
        'catalogKits',
        'catalogProducts',
        'shops',
        'orders',
        'clients',
      ].includes(route.name as string),
    )
    const toggleMenu = () => {
      UIStore.toggleMenu()
      setSessionStorageItem('isMenuOpen', UIStore.getIsMenuOpen)
    }

    const checkMenuVisibility = () => {
      if (window.innerWidth < notebookBreakpoint && UIStore.getIsMenuOpen) {
        toggleMenu()
      }
    }

    onClickOutside(menuRef, () => {
      const hasPopupOpen = (
        el: MaybeElementRef | null,
      ): el is MaybeElementRef & {
        isConfirmPopupOpen: boolean
        isCompanyMenuItemFormOpen: boolean
      } => {
        return (
          el != null
          && (('isConfirmPopupOpen' in el && (el.isConfirmPopupOpen as boolean))
            || ('isCompanyMenuItemFormOpen' in el
              && (el.isCompanyMenuItemFormOpen as boolean)))
        )
      }
      if (
        window.innerWidth < notebookBreakpoint
        && menuRef.value
        && !hasPopupOpen(menuRef.value)
      ) {
        toggleMenu()
      }
    })

    onBeforeMount(async () => {
      await companyStore.getMenuSections()
    })

    onMounted(() => {
      window.addEventListener('resize', checkMenuVisibility)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkMenuVisibility)
    })

    return {
      UIStore,
      authStore,
      menuRef,
      userMenuItems,
      toggleMenu,
      isFilter,
      handleSignOut,
    }
  },
})
