<template>
  <form class="page-form" @submit.prevent="submitHandler">
    <SelectElement
      :value="selectedItem ?? ''"
      :options="productOptions"
      label="title"
      trackBy="id"
      objectKey="item"
      :title="props.type === 'products' ? 'Выберите товар' : 'Выберите услугу'"
      titleClass="medium-18"
      placeholder="Выберите товар"
      @dataChanged="valueUpdate"
    />
    <FormActionBtns
      reversed
      class="page-form__btn-container"
      :isLoading="isLoading"
      :disabled="!selectedItem"
      @cancel="$emit('close')"
    >
      Добавить
    </FormActionBtns>
  </form>
</template>

<script lang="ts" src="./HomePageForm.ts"></script>

<style scoped lang="scss" src="./HomePageForm.scss"></style>
