import type {
  CompleteLoginResponseData,
  CompleteLoginSuccessResponse,
  LoginFormPayload,
  LoginSuccessResponse,
  SMSLoginFormPayload,
  SMSSendPayload,
  ChangeRegistryPhonePayload,
} from '@/types/auth'
import type { DefaultError } from '@/types/httpError'
import type { UserData } from '@/types/user'
import type { RootActions, RootGetters, RootState } from './types'
import {
  loginAdminApiCall,
  sendSMSAdminApiCall,
  smsLoginAdminApiCall,
  logoutApiCall,
  changeRegistryPhoneApiCall,
} from '@/api/auth'
import { useApiCall } from '@/composables/useApiCall'
import { AUTH_ERROR_MESSAGES_BY_CODE } from '@/constants/errorMessages'
import {
  getStorageItemWithExpiry,
  setStorageItemWithExpiry,
} from '@/helpers/localStorage'
import { defineStore } from 'pinia'

const authToken = getStorageItemWithExpiry<string>('authToken')

export const useAuthStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('auth', {
  state() {
    if (!authToken) {
      localStorage.clear()
    }

    return {
      token: authToken,
      tokenType: getStorageItemWithExpiry<string>(`tokenType_${authToken}`),
      user: getStorageItemWithExpiry<UserData>(`user_${authToken}`),
      loginResponse: null,
      sendCodeResponse: null,
      error: null,
    }
  },
  getters: {
    isAuth: (state: RootState) => !!state.token,
  },
  actions: {
    async clearStore() {
      this.token = null
      this.user = null
      localStorage.clear()
    },
    async writeData(payload: CompleteLoginResponseData) {
      const { token, expiresIn, user, tokenType } = payload
      this.$patch({
        token: token.accessToken,
        tokenType,
        user,
      })
      setStorageItemWithExpiry('authToken', token.accessToken, expiresIn * 1000)
      setStorageItemWithExpiry(
        'refreshToken',
        token.refreshToken,
        expiresIn * 1000,
      )
      setStorageItemWithExpiry(`user_${token.accessToken}`, user)
      setStorageItemWithExpiry(`tokenType_${token.accessToken}`, tokenType)
    },
    async adminLogin(payload: LoginFormPayload) {
      this.error = null

      const {
        data: adminLoginData,
        executeApiCall: adminLoginAction,
        error: adminLoginError,
      } = useApiCall<LoginSuccessResponse, DefaultError, LoginFormPayload>(
        loginAdminApiCall,
        true,
        payload,
      )

      try {
        await adminLoginAction()
        if (adminLoginData.value) {
          this.loginResponse = adminLoginData.value.data.message
        }
      }
      catch {
        if (adminLoginError.value) {
          if (adminLoginError.value.status === 404) {
            this.error = {
              error: AUTH_ERROR_MESSAGES_BY_CODE[404],
            }
            return
          }

          this.error = adminLoginError.value.data

          if (adminLoginError.value.status === 422) {
            this.error = {
              ...this.error,
              error: AUTH_ERROR_MESSAGES_BY_CODE[422],
            }
          }
        }
      }
    },
    async adminCompleteLogin(payload: SMSLoginFormPayload) {
      this.error = null

      const {
        data: adminSMSLoginData,
        executeApiCall: adminSMSLoginAction,
        error: adminSMSLoginError,
      } = useApiCall<
        CompleteLoginSuccessResponse,
        DefaultError,
        SMSLoginFormPayload
      >(smsLoginAdminApiCall, true, payload)

      try {
        await adminSMSLoginAction()
        if (adminSMSLoginData.value) {
          await this.writeData(adminSMSLoginData.value.data)
        }
      }
      catch {
        if (adminSMSLoginError.value) {
          this.error = adminSMSLoginError.value.data
        }
      }
    },
    async changeRegistryPhone(payload: ChangeRegistryPhonePayload) {
      this.error = null

      const {
        // data: changeRegistryPhoneData,
        executeApiCall: changeRegistryPhoneAction,
        error: changeRegistryPhoneError,
      } = useApiCall<'', DefaultError, ChangeRegistryPhonePayload>(
        changeRegistryPhoneApiCall,
        true,
        payload,
      )

      try {
        await changeRegistryPhoneAction()
      }
      catch {
        if (changeRegistryPhoneError.value) {
          this.error = changeRegistryPhoneError.value.data
          throw changeRegistryPhoneError.value.data
        }
      }
    },
    async adminSendSMS(payload: SMSSendPayload) {
      this.error = null

      const {
        data: sensSMSData,
        executeApiCall: sendSMSAction,
        error: sendSMSError,
      } = useApiCall<'', DefaultError, SMSSendPayload>(
        sendSMSAdminApiCall,
        true,
        payload,
      )

      try {
        await sendSMSAction()

        if (sensSMSData.value) {
          this.sendCodeResponse = sensSMSData.value
        }
      }
      catch {
        if (sendSMSError.value) {
          this.error = sendSMSError.value.data
        }
      }
    },
    async logout() {
      this.error = null

      const {
        executeApiCall: logoutAction,
        error: logoutError,
      } = useApiCall<'', DefaultError>(logoutApiCall, true)

      try {
        await logoutAction()
        await this.clearStore()
      }
      catch {
        if (logoutError.value) {
          this.error = logoutError.value.data
          throw logoutError.value.data
        }
      }
    },
  },
})
