import { useFilterStore } from '@/store/filter'
import { useShopsStore } from '@/store/shops'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'ShopsFilter',
  emits: ['closePopup'],
  setup(_, { emit }) {
    const currentParent = ref<{ name: string, id: number } | null>(null)
    const currentActive = ref<null | 1 | 0>(null)
    const shopsStore = useShopsStore()
    const filterStore = useFilterStore()

    const changeActive = (value: 1 | 0) => {
      currentActive.value = value
      filterStore.setFilterItem('active', {
        title: 'Активность',
        name: value ? 'Да' : 'Нет',
        value,
      })
    }

    const applyFilter = async () => {
      await shopsStore.applyFilter({
        search: filterStore.filters.search,
        active: currentActive.value !== null ? filterStore.filters.selected?.active?.value.toString() : '',
      })
      filterStore.isSend = false
      emit('closePopup')
    }

    const clearFilter = async () => {
      filterStore.clearFilter()
      await shopsStore.clearFilter()
    }

    watch(() => filterStore.filters?.selected?.active, (value, oldValue) => {
      if (oldValue && !value) {
        currentActive.value = null
      }
    }, { deep: true })

    watch(() => filterStore.isSend, async () => {
      if (filterStore.isSend) {
        await applyFilter()
      }
    })

    return {
      shopsStore,
      filterStore,
      currentParent,
      currentActive,
      changeActive,
      clearFilter,
      applyFilter,
    }
  },
})
