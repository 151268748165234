<template>
  <div class="dropzone">
    <span class="dropzone__title">{{ title }}</span>
    <input ref="inputChangeRef" :accept="acceptTypes" type="file" class="dropzone__add-input" @change="onFileChange">
    <ul ref="refImages" class="dropzone__images">
      <template v-if="images.length">
        <li v-for="item in images" :key="item.id" :style="{ height: `${height}px` }" class="dropzone__item">
          <img :src="item.url" class="dropzone__item-image">
          <div class="dropzone__item-actions">
            <button
              type="button"
              title="Изменить"
              class="dropzone__item-button"
              @click="editHandler(item.id)"
            >
              <SvgIcon icon="edit" />
            </button>
            <button
              v-if="!withOutDelete"
              type="button"
              title="Удалить"
              class="dropzone__item-button --delete"
              @click="onDeleteImageClick(item.id)"
            >
              <SvgIcon icon="x" />
            </button>
          </div>
        </li>
      </template>
      <li
        v-if="(!images?.length && !multiple) || multiple" class="dropzone__add"
        :style="{ height: `${height}px` }"
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent="toggleActive"
      >
        <label for="dropZoneFile" class="dropzone__add-label">
          <SvgIcon icon="add-image" class="dropzone__add-icon" />
          <span class="dropzone__add-title">{{ dropzoneTitle }}</span>
          <span class="dropzone__add-description">{{ dropzoneDescription }}</span>
        </label>
        <input id="dropZoneFile" :multiple="multiple" :accept="acceptTypes" type="file" class="dropzone__add-input" @change="onFileAdd">
      </li>
    </ul>
  </div>
</template>

<script lang="ts" src="./AddImage.ts"></script>

<style scoped lang="scss" src="./AddImage.scss"></style>
