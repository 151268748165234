import type {
  ChangeAndroidAppDataPayload,
  ChangeIOSAppContactsPayload,
  ChangeIOSAppDataPayload,
  ChangeMainDataPayload,
  DeleteImageActionParams,
  DeleteImagePayload,
  DeleteImageResponse,
  GetAppData,
  GetAppDataSuccessResponse,
  IImage,
  UploadAppImagesResponse,
  UploadIOSAndroidAppImages,
} from '@/types/app'
import type { DefaultError } from '@/types/httpError'
import type { RootActions, RootGetters, RootState } from './types'
import {
  androidAppDataChangeApiCall,
  androidAppUploadImagesApiCall,
  deleteImageApiCall,
  getAppDataApiCall,
  iOSAppContactsChangeApiCall,
  iOSAppDataChangeApiCall,
  iosAppUploadImagesApiCall,
  mainAppDataChangeApiCall,
  mainAppUploadImagesApiCall,
} from '@/api/app'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'

export const useAppStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('app', {
  state() {
    return {
      main: null,
      iosApp: null,
      androidApp: null,
      publicationsTab: 'ios',
      error: null,
      tempAppIcons: {
        ios: '',
        android: '',
      },
    }
  },
  getters: {
    getMainAppData(state) {
      return state.main
    },
    getiOSAppData(state) {
      return state.iosApp
    },
    getAndroidAppData(state) {
      return state.androidApp
    },
  },
  actions: {
    setMainAppData(payload: GetAppData) {
      const {
        iconName,
        logoPdf,
        logoPng,
        logoSvg,
        splashScreenText,
        companyName,
        colors,
        jivo,
        state,
        authType,
        twoFactor,
        hasDelivery,
        hasFavorite,
        hasMarket,
        hasPickup,
        hasServices,
      } = payload

      this.main = {
        colors,
        iconName: iconName ?? '',
        companyName: companyName ?? '',
        logo: {
          svg: logoSvg,
          pdf: logoPdf,
          png: logoPng,
        },
        splashScreenText: splashScreenText ?? '',
        jivo: jivo ?? '',
        state,
        authType,
        twoFactor,
        hasDelivery,
        hasFavorite,
        hasMarket,
        hasPickup,
        hasServices,
      }
    },
    setIOSAppData(payload: GetAppData) {
      const { iosSettings } = payload
      const {
        name,
        address,
        email,
        phone,
        copyright,
        title,
        userAgreementUrl,
        supportUrl,
        description,
        keyWords,
        appIcon,
        ...rest
      } = iosSettings
      this.iosApp = {
        contacts: {
          name: name ?? '',
          address: address ?? '',
          email: email ?? '',
          phone: phone ?? '',
          copyright: copyright ?? '',
        },
        title: title ?? '',
        userAgreementUrl: userAgreementUrl ?? '',
        supportUrl: supportUrl ?? '',
        description: description ?? '',
        keyWords: keyWords ?? '',
        appIcon,
        images: rest,
      }
    },
    setAndroidAppData(payload: GetAppData) {
      const { androidSettings } = payload

      const {
        androidAppIcon,
        title,
        shortDescription,
        description,
        userAgreementUrl,
        userDeleteFormUrl,
        defaultLanguage,
        appCategory,
        marketBanner,
        marketImage,
      } = androidSettings
      this.androidApp = {
        appIcon: Array.isArray(androidAppIcon)
          ? androidAppIcon[0]
          : androidAppIcon,
        title: title ?? '',
        shortDescription: shortDescription ?? '',
        description: description ?? '',
        userAgreementUrl: userAgreementUrl ?? '',
        userDeleteFormUrl: userDeleteFormUrl ?? '',
        defaultLanguage: defaultLanguage ?? '',
        appCategory: appCategory ?? '',
        images: {
          marketBanner,
          marketImage,
        },
      }
    },
    setError(error: DefaultError) {
      this.error = error
    },
    setTempIcons(key: 'ios' | 'android', link: string) {
      this.tempAppIcons[key] = link
    },
    resetTempIcons() {
      this.tempAppIcons = {
        ios: '',
        android: '',
      }
    },
    async getAppData() {
      const {
        data: appData,
        error: appError,
        executeApiCall: getAppDataAction,
      } = useApiCall<GetAppDataSuccessResponse, DefaultError>(
        getAppDataApiCall,
        true,
      )

      try {
        await getAppDataAction()
        if (appData.value) {
          const data = appData.value.data
          this.setIOSAppData(data)
          this.setMainAppData(data)
          this.setAndroidAppData(data)
        }
      } catch {
        if (appError.value?.data) {
          // if ('data' in appError.value?.data) {
          //   this.error = appError.value?.data.data
          //   return
          // }
          this.setError(appError.value.data)
        }
      }
    },
    async changeMainAppData(params: ChangeMainDataPayload) {
      const {
        data: mainAppData,
        executeApiCall: changeMainData,
        error: mainAppError,
      } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        ChangeMainDataPayload
      >(mainAppDataChangeApiCall, true, params)
      try {
        await changeMainData()

        if (mainAppData.value) {
          this.setMainAppData(mainAppData.value.data)
        }
      } catch {
        if (mainAppError.value) {
          this.setError(mainAppError.value.data)
        }
      }
    },
    async changeIOSAppData(params: Partial<ChangeIOSAppDataPayload>) {
      const {
        data: changeIOSDataResponse,
        executeApiCall: changeIOSData,
        error: changeIOSDataError,
      } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        Partial<ChangeIOSAppDataPayload>
      >(iOSAppDataChangeApiCall, true, params)

      try {
        await changeIOSData()
        if (changeIOSDataResponse.value) {
          this.setIOSAppData(changeIOSDataResponse.value.data)
        }
      } catch {
        if (changeIOSDataError.value) {
          // if ('data' in changeIOSDataError.value?.data) {
          //   this.error = changeIOSDataError.value?.data.data
          //   return
          // }
          this.setError(changeIOSDataError.value.data)
          throw changeIOSDataError.value.data
        }
      }
    },
    async changeIOSAppContacts(params: ChangeIOSAppContactsPayload) {
      const {
        data: changeIOSContactsResponse,
        executeApiCall: changeIOSContacts,
        error: changeIOSContactsError,
      } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        ChangeIOSAppContactsPayload
      >(iOSAppContactsChangeApiCall, true, params)

      try {
        await changeIOSContacts()
        if (changeIOSContactsResponse.value) {
          this.setIOSAppData(changeIOSContactsResponse.value.data)
        }
      } catch {
        if (changeIOSContactsError.value) {
          // if ('data' in changeIOSContactsError.value?.data) {
          //   this.error = changeIOSContactsError.value?.data.data
          //   return
          // }
          this.setError(changeIOSContactsError.value.data)
          throw changeIOSContactsError.value.data
        }
      }
    },
    async changeAndroidAppData(params: ChangeAndroidAppDataPayload) {
      const {
        data: changeAndroidDataResponse,
        executeApiCall: changeAndroidData,
        error: changeAndroidDataError,
      } = useApiCall<
        GetAppDataSuccessResponse,
        DefaultError,
        ChangeAndroidAppDataPayload
      >(androidAppDataChangeApiCall, true, params)

      try {
        await changeAndroidData()
        if (changeAndroidDataResponse.value) {
          this.setAndroidAppData(changeAndroidDataResponse.value.data)
        }
      } catch {
        if (changeAndroidDataError.value) {
          // if ('data' in changeAndroidDataError.value) {
          //   this.error = changeAndroidDataError.value.data.data
          //   return
          // }
          this.setError(changeAndroidDataError.value.data)
          throw changeAndroidDataError.value.data
        }
      }
    },
    async uploadMainAppIcons(params: FormData) {
      const {
        data: appData,
        executeApiCall: uploadMainAppIcons,
        error: appError,
      } = useApiCall<UploadAppImagesResponse, DefaultError, FormData>(
        mainAppUploadImagesApiCall,
        true,
        params,
        false,
      )
      try {
        await uploadMainAppIcons()
        if (appData.value) {
          this.setMainAppData(appData.value.data)
        }
      } catch {
        if (appError.value) {
          this.error = appError.value.data
          throw appError.value.data
        }
      }
    },
    async uploadIOSAppImages(params: FormData) {
      const {
        data: appData,
        executeApiCall: uploadIOSAppImages,
        error: appError,
      } = useApiCall<UploadIOSAndroidAppImages, DefaultError, FormData>(
        iosAppUploadImagesApiCall,
        true,
        params,
        false,
      )
      try {
        await uploadIOSAppImages()
        return appData.value?.data as IImage[]
      } catch {
        if (appError.value) {
          this.error = appError.value.data
          throw appError.value.data
        }
      }
      return null
    },
    async uploadAndroidAppImages(params: FormData) {
      const {
        data: appData,
        executeApiCall: uploadAndroidAppImages,
        error: appError,
      } = useApiCall<UploadIOSAndroidAppImages, DefaultError, FormData>(
        androidAppUploadImagesApiCall,
        true,
        params,
        false,
      )
      try {
        await uploadAndroidAppImages()
        return appData.value?.data as IImage[]
      } catch {
        if (appError.value) {
          this.error = appError.value.data
          throw appError.value.data
        }
        return null
      }
    },
    async deleteAppImage(params: DeleteImageActionParams) {
      const { executeApiCall: deleteImage, error: deleteImageError }
        = useApiCall<DeleteImageResponse, DefaultError, DeleteImagePayload>(
          deleteImageApiCall,
          true,
          params,
        )

      try {
        await deleteImage()
      } catch {
        if (deleteImageError.value) {
          this.error = deleteImageError.value.data
        }
        throw deleteImageError.value?.data
      }
    },
  },
})
