import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getBannersApiCall,
  createBannerApiCall,
  updateBannerApiCall,
  updateBannerImageApiCall,
  deleteBannerApiCall,
} from '@/api/banners'
import type { RootState, RootGetters, RootActions } from './types'
import type { DefaultError } from '@/types/httpError'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'
import type {
  IBanner,
  GetBannersResponse,
  CreateUpdateBannerResponse,
  UpdateBannerPayload,
  UpdateBannerImagePayload,
} from '@/types/banners'

export const useBannersStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('banners', {
  state() {
    return {
      banners: null,
      error: null,
    }
  },
  getters: {},
  actions: {
    replaceBanner(id: number, body?: IBanner) {
      if (this.banners) {
        if (body) {
          const index = this.banners.findIndex((i) => i.id === id)
          this.banners[index] = body
        } else {
          this.banners = this.banners.filter((i) => i.id !== id)
        }
      }
    },
    async getBanners() {
      const { data, executeApiCall, error } = useApiCall<
        GetBannersResponse,
        DefaultError
      >(getBannersApiCall, true)

      try {
        await executeApiCall()
        if (data.value) {
          this.banners = data.value.data
        }
      } catch {
        console.error(error)
      }
    },
    async createBanner(params: FormData) {
      const { data, executeApiCall, error } = useApiCall<
        CreateUpdateBannerResponse,
        DefaultError,
        FormData
      >(createBannerApiCall, true, params, false)

      try {
        await executeApiCall(params)
        if (data.value) {
          if (!this.banners) {
            this.banners = []
          }
          this.banners = [...this.banners, data.value.data]
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
        }
        console.error(error.value)
      }
    },
    async updateBanner(params: UpdateBannerPayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateUpdateBannerResponse,
        DefaultError,
        UpdateBannerPayload
      >(updateBannerApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          const banner = data.value.data
          this.replaceBanner(banner.id, banner)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
        }
        console.error(error.value)
      }
    },
    async updateBannerImage(params: UpdateBannerImagePayload) {
      const { data, executeApiCall, error } = useApiCall<
        CreateUpdateBannerResponse,
        DefaultError,
        UpdateBannerImagePayload
      >(updateBannerImageApiCall, true, params, false)

      try {
        await executeApiCall()
        if (data.value) {
          const banner = data.value.data
          this.replaceBanner(banner.id, banner)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
        }
        console.error(error.value)
      }
    },
    async deleteBanner(params: DefaultDeletePayload) {
      const { data, executeApiCall, error } = useApiCall<
        DeleteSuccessResponse,
        DefaultError,
        DefaultDeletePayload
      >(deleteBannerApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceBanner(params.id)
        }
      } catch {
        if (error.value) {
          this.error = error.value.data
        }
        console.error(error.value)
      }
    },
  },
})
