import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'TagElement',
  props: {
    text: {
      type: String,
    },
    status: {
      type: String as PropType<'success' | 'info' | 'fail' | 'secondary'>,
      default: 'fail',
    },
  },
  setup() {
    return {}
  },
})
