<template>
  <form class="banner-form" @submit.prevent="submitHandler">
    <div class="banner-form__content">
      <div>
        <h4>Наименование</h4>
        <FormElement
          v-model:value.trim="fields.title"
          placeholder="Введите наименование"
          :errorText="errorFields?.title"
        />
      </div>
      <div>
        <h4>Категория товара</h4>
        <SelectElement
          :value="selectedCategory"
          :options="categories"
          objectKey="categoryId"
          label="name"
          trackBy="id"
          placeholder="Выберите категорию товара"
          :errorText="errorFields?.categoryId"
          @dataChanged="onUpdateValue"
        />
      </div>
      <div>
        <h4>Фото</h4>
        <LoaderWithPreview
          id="image"
          :preview="fields.image.preview"
          accept=".jpg,.png"
          text="Добавить фото"
          secondaryText="1920 px - 1280 px; не более 3 Мб; jpg, png"
          class="banner-form__load"
          :errorText="photoErrors || errorFields?.image"
          @load="onFileUpload"
          @remove="onRemoveImage"
        />
      </div>
      <CheckboxRadioElement
        :value="!fields.isActive"
        :checked="fields.isActive"
        label="Активен"
        fieldKey="isActive"
        @update:value="onUpdateValue"
      />
      <NotifyComponent v-if="error" :text="error" />
    </div>
    <FormActionBtns
      reversed
      class="banner-form__btns"
      :isLoading="isReqLoading"
      :disabled="isSubmitBtnDisabled"
      @cancel="$emit('close')"
    >
      {{ banner ? 'Сохранить  изменения' : 'Создать баннер' }}
    </FormActionBtns>
  </form>
</template>

<script lang="ts" src="./HomeBannerForm.ts"></script>

<style scoped lang="scss" src="./HomeBannerForm.scss"></style>
