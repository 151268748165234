import { defineComponent, computed, onBeforeMount, ref } from 'vue'
import { useCatalogStore } from '@/store/catalog'
import { useMainPageStore } from '@/store/mainPage'
import type { PropType } from 'vue'
import type { CatalogProduct } from '@/types/catalog'
import type {
  IPageFormProps,
  AddMainPageBlockProductPayload,
  UpdateMainPageBlockProductPayload,
} from '@/types/mainPage'

interface ISelectItem {
  id: number
  title: string
}

export default defineComponent({
  name: 'HomePageForm',
  props: {
    props: {
      type: Object as PropType<IPageFormProps>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const selectedItem = ref<ISelectItem | null>(null)
    const productOptions = ref<ISelectItem[]>([])
    const isEditing = computed(() => !!props.props.selectedPage)
    const isLoading = ref(false)
    const error = ref('')

    const catalogStore = useCatalogStore()
    const mainPageStore = useMainPageStore()

    const valueUpdate = (key: string, value: ISelectItem) => {
      selectedItem.value = value
    }

    const resetErrors = () => {
      mainPageStore.error = null
      error.value = ''
    }

    const handleAddProduct = async () => {
      isLoading.value = true
      resetErrors()

      if (selectedItem.value) {
        const body: AddMainPageBlockProductPayload = {
          blockId: +props.props.blockId,
          productId: selectedItem.value.id,
        }
        await mainPageStore.addBlockProduct(body)
      }
      isLoading.value = false

      if (mainPageStore.error) {
        error.value = mainPageStore.error.error
        return
      }

      emit('close')
    }

    const handleUpdateProduct = async () => {
      isLoading.value = true
      resetErrors()

      if (selectedItem.value && props.props.selectedPage) {
        const body: UpdateMainPageBlockProductPayload = {
          blockId: +props.props.blockId,
          pageId: props.props.selectedPage.id,
          data: {
            productId: selectedItem.value.id,
          },
        }
        await mainPageStore.updateBlockProduct(body)
      }
      isLoading.value = false

      if (mainPageStore.error) {
        error.value = mainPageStore.error.error
        return
      }

      emit('close')
    }

    const submitHandler = computed(() =>
      isEditing.value ? handleUpdateProduct : handleAddProduct,
    )

    onBeforeMount(async () => {
      if (props.props.type === 'products') {
        await catalogStore.getProductListForSelect()
        const products
          = catalogStore.productsListForSelect as unknown as CatalogProduct[]
        if (products?.length) {
          productOptions.value = products.map((p) => ({
            id: p.id,
            title: p.title,
          }))
        }
        const selectedPageOption = productOptions.value.find(
          (o) => o.id === props.props.selectedPage?.productId,
        )
        if (selectedPageOption) {
          selectedItem.value = selectedPageOption
        }
      }
    })
    return {
      productOptions,
      selectedItem,
      isEditing,
      isLoading,
      valueUpdate,
      submitHandler,
    }
  },
})
