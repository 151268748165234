import type {
  ClientItemPayload,
  ClientItemResponse,
  ClientsPayload,
  ClientsResponse,
  Client,
} from '@/types/clients'
import type { ItemPayload } from '../types/main'
import axios from 'axios'

const getClientsCall = async (
  abortController?: AbortController,
  params?: ClientsPayload,
): Promise<ClientsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/customers', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getClientsForSelectCall = async (
  abortController?: AbortController,
): Promise<Client[]> => {
  const { data } = await axios.get('/dashboard/v1/chief/customers/dropout', {
    signal: abortController?.signal,
  })
  return data.data
}

const getClientItemCall = async (
  abortController?: AbortController,
  params?: ItemPayload,
): Promise<ClientItemResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/customers/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

const editClientItemCall = async (
  abortController?: AbortController,
  params?: ClientItemPayload,
): Promise<ClientItemResponse> => {
  const { data } = await axios.put(`/dashboard/v1/chief/customers/${params?.id}`, { ...params?.data }, {
    signal: abortController?.signal,
  })
  return data
}

export { editClientItemCall, getClientItemCall, getClientsCall, getClientsForSelectCall }
