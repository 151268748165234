export const COMPANY_MENU_ITEM_CANVAS_OPTIONS_TITLES = {
  photo: 'Фотогалерея',
  html_text: 'Текст',
  license: 'Сертификаты',
}

export const INFO_BLOCK_HEADINGS = [
  {
    text: 'Наименование страницы',
    value: 'name',
  },
  {
    text: 'Активность',
    value: 'isActive',
  },
]

export const INFO_BLOCK_ADD_BTN_TEXT_PART = {
  promo: 'страницу',
  products: 'товар',
  // services: 'услугу',
}

export const INFO_BLOCK_CONFIRM_POPUP_CONTENT = {
  block: 'Вы действительно хотите удалить информационный блок?',
  page: 'Вы действительно хотите удалить страницу?',
}
