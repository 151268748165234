import type { GetAppDataSuccessResponse } from '@/types/app'
import type {
  DefaultDeletePayload,
  DeleteSuccessResponse,
  IPageQuery,
  MakeOptional,
} from '@/types/main'
import type {
  AddLoyaltyBonusLevelPayload,
  BonusLoyaltyResponse,
  ChangeLoyaltyBonusLevelPayload,
  ChangeOrderStatusPayload,
  ChangeOrderStatusResponse,
  ChangeUserAuthSettingsPayload,
  CreateOrderStatusPayload,
  GetOrderStatusesCodes,
  GetOrderStatusesResponse,
  GetOrderStatusesForSelectResponse,
  LoyaltyChangePayload,
  ToggleStoreActivityPayload,
  GetProductDetailsResponse,
  CreateProductDetailPayload,
  EditProductDetailPayload,
  CreateEditProductDetailResponse,
  GetProductDetailEnumsPayload,
  GetProductDetailEnumsResponse,
  CreateProductDetailEnumPayload,
  UpdateAcquiringPayload,
  AcquiringResponse,
  GetNavBarItemResponse,
  UpdateNavBarItemPayload,
  UpdateNavBarItemResponse,
} from '@/types/settings'
import axios from 'axios'

export const changeUserAuthSettingsApiCall = async (
  abortController?: AbortController,
  params?: ChangeUserAuthSettingsPayload,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/application/auth',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getLoyaltySettingsApiCall = async (
  abortController?: AbortController,
): Promise<BonusLoyaltyResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/loyalty', {
    signal: abortController?.signal,
  })
  return data
}

export const changeLoyaltySettingsApiCall = async (
  abortController?: AbortController,
  params?: Partial<LoyaltyChangePayload>,
): Promise<BonusLoyaltyResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/loyalty',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const addLoyaltyBonusLevelApiCall = async (
  abortController?: AbortController,
  params?: AddLoyaltyBonusLevelPayload,
): Promise<BonusLoyaltyResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/loyalty/bonus/level',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const changeLoyaltyBonusLevelApiCall = async (
  abortController?: AbortController,
  params?: ChangeLoyaltyBonusLevelPayload,
): Promise<BonusLoyaltyResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/loyalty/bonus/level/${params?.id}`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteLoyaltyBonusLevelApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/loyalty/bonus/level/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const toggleStoreActivityApiCall = async (
  abortController?: AbortController,
  params?: ToggleStoreActivityPayload,
): Promise<GetAppDataSuccessResponse> => {
  const { data } = await axios.put(
    '/dashboard/v1/chief/market',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getStoreStatusesApiCall = async (
  abortController?: AbortController,
  params?: MakeOptional<IPageQuery, 'page'>,
): Promise<GetOrderStatusesResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/statuses', {
    params,
    signal: abortController?.signal,
  })
  return data
}

export const getStoreStatusesForSelectApiCall = async (
  abortController?: AbortController,
): Promise<GetOrderStatusesForSelectResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/statuses/dropout', {
    signal: abortController?.signal,
  })
  return data
}

export const getStoreStatusCodesApiCall = async (
  abortController?: AbortController,
): Promise<GetOrderStatusesCodes> => {
  const { data } = await axios.get(
    '/dashboard/v1/chief/market/statuses/codes',
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const createOrderStatusApiCall = async (
  abortController?: AbortController,
  params?: CreateOrderStatusPayload,
): Promise<ChangeOrderStatusResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/market/statuses',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const changeOrderStatusApiCall = async (
  abortController?: AbortController,
  params?: ChangeOrderStatusPayload,
): Promise<ChangeOrderStatusResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/statuses/${params?.id}`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteOrderStatusApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/statuses/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getStoreProductDetailsApiCall = async (
  abortController?: AbortController,
  params?: MakeOptional<IPageQuery, 'page'>,
): Promise<GetProductDetailsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/properties', {
    signal: abortController?.signal,
    params,
  })
  return data
}

export const createStoreProductDetailApiCall = async (
  abortController?: AbortController,
  params?: CreateProductDetailPayload,
): Promise<CreateEditProductDetailResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/market/properties',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const editStoreProductDetailApiCall = async (
  abortController?: AbortController,
  params?: EditProductDetailPayload,
): Promise<CreateEditProductDetailResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/properties/${params?.id}`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getStoreProductDetailEnumsApiCall = async (
  abortController?: AbortController,
  params?: GetProductDetailEnumsPayload,
): Promise<GetProductDetailEnumsResponse> => {
  const { data } = await axios.get(
    `/dashboard/v1/chief/market/properties/${params?.id}/enums`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const createStoreProductDetailEnumApiCall = async (
  abortController?: AbortController,
  params?: CreateProductDetailEnumPayload,
): Promise<GetProductDetailEnumsResponse> => {
  const { data } = await axios.post(
    `/dashboard/v1/chief/market/properties/enums`,
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteStoreProductDetailEnumApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/properties/enums/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteStoreProductDetailApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/properties/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getAcquiringApiCall = async (
  abortController?: AbortController,
): Promise<AcquiringResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/acquiring', {
    signal: abortController?.signal,
  })
  return data
}

export const updateAcquiringApiCall = async (
  abortController?: AbortController,
  params?: UpdateAcquiringPayload,
): Promise<AcquiringResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/acquiring/${params?.id}`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getNavBarItemsApiCall = async (
  abortController?: AbortController,
): Promise<GetNavBarItemResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/navbar-items', {
    signal: abortController?.signal,
  })
  return data
}

export const updateNavBarItemApiCall = async (
  abortController?: AbortController,
  params?: UpdateNavBarItemPayload,
): Promise<UpdateNavBarItemResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/navbar-items/${params?.id}`,
    { ...params?.data },
    {
      signal: abortController?.signal,
    },
  )
  return data
}
