import { defineComponent, ref, reactive, computed, onBeforeMount } from 'vue'
import draggableComponent from 'vuedraggable'
import HomeInfoForm from '@/components/HomeInfoForm/HomeInfoForm.vue'
import HomeInfoBlock from '@/components/HomeInfoBlock/HomeInfoBlock.vue'
import HomePageForm from '@/components/HomePageForm/HomePageForm.vue'
import { useMainStore } from '@/store/main/main'
import { useMainPageStore } from '@/store/mainPage'
import { useRouter } from 'vue-router'
import { INFO_BLOCK_CONFIRM_POPUP_CONTENT } from '@/constants/company'
import type {
  IPageFormProps,
  IMainPageBlock,
  IProductBlockPage,
} from '@/types/mainPage'
import type { DefaultError } from '@/types/httpError'

interface IItemForDelete {
  blockId: number | null
  pageId?: number | null
  type: 'block' | 'page' | ''
  template?: string
}

export default defineComponent({
  name: 'HomeInfoBlocks',
  components: {
    HomeInfoForm,
    HomeInfoBlock,
    HomePageForm,
    draggableComponent,
  },

  setup() {
    const mainPageStore = useMainPageStore()
    const mainStore = useMainStore()
    const router = useRouter()

    const blocks = computed<IMainPageBlock[]>(() => mainPageStore.blocks ?? [])
    const selectedBlock = ref<IMainPageBlock | null>(null)
    const pagePopupProps = ref<IPageFormProps>({
      blockName: '',
      type: '',
      blockId: '',
      selectedPage: null,
    })
    const openedPopup = reactive({
      confirm: false,
      block: false,
      page: false,
    })
    const itemForDelete = ref<IItemForDelete>({
      blockId: null,
      type: '',
    })
    const isConfirmReqLoading = ref(false)
    const reqError = ref('')
    const isDragging = ref(false)
    const isLoading = ref(false)

    const isTouchDevice = computed(
      () => mainStore.isMobile || mainStore.isTablet,
    )

    const pagePopupTitle = computed(() => {
      const { type, blockName, selectedPage } = pagePopupProps.value
      const isEditing = selectedPage
      return `${isEditing ? 'Редактировать' : 'Добавить'} ${
        type === 'products' ? 'товар' : 'услугу'
      } в блок${isEditing ? 'е' : ''} "${blockName}"`
    })

    const confirmPopupContent = computed(() =>
      itemForDelete.value.type
        ? INFO_BLOCK_CONFIRM_POPUP_CONTENT[itemForDelete.value.type]
        : '',
    )

    const onOpenInfoForm = (id?: number) => {
      if (id) {
        selectedBlock.value = blocks.value?.find((i) => i.id === id) ?? null
      }
      openedPopup.block = true
    }

    const onCloseInfoForm = () => {
      openedPopup.block = false
      if (selectedBlock.value) {
        selectedBlock.value = null
      }
    }

    const onOpenPageForm = (
      type: 'products' | 'services' | 'promo',
      blockId: number,
      heading?: string,
      page?: IProductBlockPage,
      position?: number,
    ) => {
      if (type === 'promo') {
        router.push(
          `/promo/${blockId}/${page?.id ? `edit/${page.id}` : 'create'}`,
        )
        if (position)
          localStorage.setItem('promoPosition', String(position))
      } else {
        pagePopupProps.value = {
          blockName: heading ?? '',
          type,
          blockId,
          selectedPage: page ?? null,
        }
        openedPopup.page = true
      }
    }

    const onClosePageForm = () => {
      openedPopup.page = false
      pagePopupProps.value = {
        blockName: '',
        type: '',
        blockId: '',
        selectedPage: null,
      }
    }

    const openConfirmPopup = () => {
      openedPopup.confirm = true
    }

    const onDeleteBlockBtnClick = (id: number) => {
      itemForDelete.value = {
        blockId: id,
        type: 'block',
      }
      openConfirmPopup()
    }

    const handleBlockDelete = async () => {
      reqError.value = ''
      isConfirmReqLoading.value = true
      const id = itemForDelete.value.blockId
      if (id) {
        await mainPageStore.deleteBlock({ id })
      }
      isConfirmReqLoading.value = false

      if (mainPageStore.error) {
        reqError.value = mainPageStore.error.error
        return
      }
      onCloseConfirmPopup()
    }

    const onDeletePageBtnClick = (
      template: string,
      blockId: number,
      pageId: number,
    ) => {
      itemForDelete.value = {
        blockId,
        pageId,
        type: 'page',
        template,
      }
      openConfirmPopup()
    }

    const handleProductPageDelete = async () => {
      reqError.value = ''
      isConfirmReqLoading.value = true
      const { blockId, pageId } = itemForDelete.value
      if (blockId && pageId) {
        try {
          await mainPageStore.deleteBlockProduct({ blockId, pageId })
          onCloseConfirmPopup()
        } catch (error) {
          reqError.value = (error as DefaultError).error
        }
      }
      isConfirmReqLoading.value = false
    }

    const handlePromoPageDelete = async () => {
      reqError.value = ''
      isConfirmReqLoading.value = true
      const { blockId, pageId } = itemForDelete.value

      if (blockId && pageId) {
        try {
          await mainPageStore.deleteBlockPromo({ blockId, pageId })
          onCloseConfirmPopup()
        } catch (error) {
          reqError.value = (error as DefaultError).error
        }
      }
      isConfirmReqLoading.value = false
    }

    const onCloseConfirmPopup = () => {
      openedPopup.confirm = false
      itemForDelete.value = {
        blockId: null,
        type: '',
      }
    }

    const deleteInstanceHandler = async () => {
      switch (itemForDelete.value.type) {
        case 'block':
          await handleBlockDelete()
          break
        case 'page':
          deletePageHandler()
          break
        default:
          break
      }
    }

    const deletePageHandler = async () => {
      switch (itemForDelete.value.template) {
        case 'products':
          await handleProductPageDelete()
          break

        case 'promo':
          await handlePromoPageDelete()
          break
        default:
          break
      }
    }

    const onBlockDragAndDropEnd = async (obj: {
      moved: {
        element: IMainPageBlock
        oldIndex: number
        newIndex: number
      }
    }) => {
      const { oldIndex, newIndex, element } = obj.moved
      if (oldIndex !== newIndex) {
        await mainPageStore.updateBlock({
          id: element.id,
          data: { sort: newIndex },
        })
      }
    }

    onBeforeMount(async () => {
      isLoading.value = true
      await mainPageStore.getBlocks()
      await mainPageStore.getTemplates()
      isLoading.value = false
    })

    return {
      blocks,
      selectedBlock,
      openedPopup,
      isDragging,
      isLoading,
      isTouchDevice,
      pagePopupTitle,
      pagePopupProps,
      isConfirmReqLoading,
      reqError,
      itemForDelete,
      confirmPopupContent,
      onOpenInfoForm,
      onCloseInfoForm,
      onOpenPageForm,
      onClosePageForm,
      onDeleteBlockBtnClick,
      onDeletePageBtnClick,
      onCloseConfirmPopup,
      onBlockDragAndDropEnd,
      deleteInstanceHandler,
    }
  },
})
