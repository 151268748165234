import { ref, onMounted, onUnmounted } from 'vue'

export const useScroll = () => {
  const content = ref<HTMLElement | null>(null)
  const scrollY = ref(0)
  const scrollX = ref(0)

  const updateScroll = () => {
    if (content.value) {
      scrollY.value = content.value.scrollTop
      scrollX.value = content.value.scrollLeft
    }
  }

  const scrollTo = (y: number, x: number) => {
    if (content.value) {
      content.value.scrollTo({
        top: y,
        left: x,
        behavior: 'smooth',
      })
    }
  }

  onMounted(() => {
    content.value = document.querySelector('.content')
    if (content.value) {
      content.value.addEventListener('scroll', updateScroll)
    }
  })

  onUnmounted(() => {
    if (content.value) {
      content.value.removeEventListener('scroll', updateScroll)
    }
  })

  return { content, scrollY, scrollX, scrollTo }
}
