<template>
  <div class="load">
    <span v-if="title" class="load__title">{{ title }}</span>
    <div
      v-if="preview"
      :style="{ backgroundImage: `url(${preview})` }"
      class="load__preview"
    >
      <AppLoader v-if="isLoading" class="load__spinner" />
      <button
        v-else
        class="load__preview-remove"
        type="button"
        @click="$emit('remove')"
      />
    </div>
    <FileLoader
      v-else
      :id="id"
      :accept="accept"
      :text="text"
      :secondaryText="secondaryText"
      class="load__input"
      @fileLoad="(evt: Event) => $emit('load', evt)"
    />
    <div v-if="errorText" class="load__error-list">
      <div v-for="(error, index) in errorText" :key="index">
        <NotifyComponent
          :text="error"
          size="sm"
          status="error"
          class="load__error"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./LoaderWithPreview.ts"></script>

<style scoped lang="scss" src="./LoaderWithPreview.scss"></style>
