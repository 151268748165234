import {
  defineComponent,
  reactive,
  ref,
  watch,
  computed,
} from 'vue'
import LoaderWithPreview from '@/components/LoaderWithPreview/LoaderWithPreview.vue'
import validateImage from '@/helpers/validateImage'
import { useBannersStore } from '@/store/banners'
import type { PropType } from 'vue'
import type { IBanner } from '@/types/banners'
import type { SelectItem } from '@/types/catalog'
import type { DefaultError } from '@/types/httpError'

interface BannerFields {
  title: string
  categoryId: number | string
  image: {
    preview: string
    value: File | null
  }
  isActive: boolean
}

export default defineComponent({
  name: 'HomeBannerForm',
  props: {
    banner: {
      type: Object as PropType<IBanner>,
    },
    categories: {
      type: Array as PropType<SelectItem[]>,
      required: true,
    },
  },
  components: {
    LoaderWithPreview,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const bannersStore = useBannersStore()

    const isReqLoading = ref(false)
    const fields = reactive<BannerFields>({
      title: '',
      categoryId: '',
      image: {
        preview: '',
        value: null,
      },
      isActive: true,
    })
    const selectedCategory = ref<SelectItem | ''>('')
    const bannerId = ref<string | number>('')
    let originalValues: string

    const error = ref('')
    const errorFields = ref<Record<string, string[]> | null>(null)
    const photoErrors = ref<string[] | null>(null)

    watch(
      () => props.banner,
      (newVal) => {
        if (newVal) {
          const {
            id,
            title,
            categoryId,
            image: originalImage,
            isActive,
          } = newVal
          bannerId.value = id
          const image = {
            preview: originalImage.previewUrl,
            value: null,
          }
          Object.assign(fields, {
            title,
            categoryId,
            image,
            isActive,
          })

          const categoryItem = props.categories.find(
            (c) => c.id === props.banner?.categoryId,
          )
          if (categoryItem) {
            selectedCategory.value = categoryItem
          }

          originalValues = JSON.stringify({
            title,
            categoryId,
            isActive,
          })
        }
      },
      { immediate: true },
    )

    const onUpdateValue = (
      key: 'isActive' | 'categoryId',
      value: SelectItem | boolean,
    ) => {
      if (key === 'isActive') {
        fields[key] = value as boolean
        return
      }
      fields[key] = (value as SelectItem).id
    }

    const onFileUpload = (e: Event) => {
      const fileInputData = e.target as HTMLInputElement
      if (fileInputData.files && fileInputData.files.length) {
        const file = fileInputData.files[0]
        const errors = validateImage(file, ['jpg', 'png'])
        if (errors.length) {
          photoErrors.value = errors
          return
        }
        const link = URL.createObjectURL(file)

        fields.image = {
          preview: link,
          value: file,
        }
      }
    }

    const onRemoveImage = () => {
      fields.image = {
        preview: '',
        value: null,
      }
    }

    const handleCreateBanner = async () => {
      isReqLoading.value = true
      bannersStore.error = null
      errorFields.value = null
      photoErrors.value = null

      const formData = new FormData()

      formData.append('title', fields.title)
      formData.append('category_id', String(fields.categoryId))
      if (fields.image.value) {
        formData.append('image', fields.image.value)
      }
      formData.append('is_active', fields.isActive ? '1' : '0')

      await bannersStore.createBanner(formData)
      isReqLoading.value = false

      if (bannersStore.error) {
        error.value = (bannersStore.error as DefaultError).error
        errorFields.value = (bannersStore.error as DefaultError).data
        return
      }

      emit('close')
    }

    const onImageUpdate = async (bannerId: number, image: File) => {
      const formData = new FormData()
      formData.append('image', image)

      await bannersStore.updateBannerImage({ id: bannerId, data: formData })
    }

    const handleUpdateBanner = async () => {
      isReqLoading.value = true
      bannersStore.error = null
      errorFields.value = null
      photoErrors.value = null
      const newImage = fields.image.value
      const id = +bannerId.value
      if (newImage) {
        await onImageUpdate(id, newImage)
      }

      if (bannersStore.error) {
        error.value = (bannersStore.error as DefaultError).error
        isReqLoading.value = false
        return
      }

      const { title, categoryId, isActive } = fields
      const stringifiedFields = JSON.stringify({ title, categoryId, isActive })

      if (stringifiedFields !== originalValues) {
        await bannersStore.updateBanner({
          id,
          data: {
            title,
            categoryId: +categoryId,
            isActive,
          },
        })
      }

      isReqLoading.value = false

      if (bannersStore.error) {
        error.value = (bannersStore.error as DefaultError).error
        errorFields.value = (bannersStore.error as DefaultError).data
        return
      }

      emit('close')
    }

    const submitHandler = computed(() =>
      props.banner ? handleUpdateBanner : handleCreateBanner,
    )

    const isSubmitBtnDisabled = computed(
      () => !fields.title || !fields.image.preview || !fields.categoryId,
    )

    return {
      isReqLoading,
      fields,
      selectedCategory,
      error,
      errorFields,
      photoErrors,
      onUpdateValue,
      onFileUpload,
      onRemoveImage,
      submitHandler,
      isSubmitBtnDisabled,
    }
  },
})
